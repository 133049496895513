import { Token, get, post, postNoResponse } from './http';

const CATALOG = '/api/Catalog';

export const getPlants = async (request: any): Promise<any> => post(`${CATALOG}/search`, request, null, 'Failed to load page');

export const saveCatalogItem = async (item: any, token: Token): Promise<any> => postNoResponse(`${CATALOG}/update`, item, token, 'Failed to save item');

export const deleteCatalogItem = async (item: any, token: Token): Promise<any> => postNoResponse(`${CATALOG}/delete`, item, token, 'Failed to delete item');

export const getFamilies = async (token: Token): Promise<any> => get(`${CATALOG}/families`, '', token, 'Failed to load families');

export const resetNew = async (token: Token): Promise<any> => postNoResponse(`${CATALOG}/reset/new`, null, token, 'Failed to reset New status');

export const resetRecommended = async (token: Token): Promise<any> =>
  postNoResponse(`${CATALOG}/reset/recommended`, null, token, 'Failed to reset Recommended status');
