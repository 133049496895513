import React from 'react';
import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import MuiDialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useDialog } from './useDialog';
import { TransitionProps } from '@mui/material/transitions';
import { getImageUrl } from 'utilities/imageUtils';

import style from './style.module.scss';

const useStyles = createUseStyles({
  form: {
    padding: 0,
  },
  appBar: {
    position: 'relative',
    background: '#293132',
  },
  title: {
    marginLeft: '2rem',
  },
  dialog: {},
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Dialog = () => {
  const { item, isDialogOpen, closeDialog, onClickFamily, openInNew } = useDialog();

  const classes = useStyles();

  return (
    item && (
      <MuiDialog fullScreen open={isDialogOpen} onClose={closeDialog} TransitionComponent={Transition} className={classes.dialog} scroll="paper">
        <DialogTitle className={classes.form}>
          <AppBar className={classes.appBar}>
            <Toolbar className={style.toolbar}>
              <IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
                <CloseIcon />
              </IconButton>
              <div className={style.title}>
                <Typography variant="h6" className={classes.title}>
                  {item.name} {item.genericName ? ` (${item.genericName})` : null}
                </Typography>
                <Typography variant="h6" className={classes.title}>
                  &pound; {item.price.toFixed(2)}
                </Typography>
              </div>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent className={classes.form}>
          <div className={style.page}>
            {item.hasImage && (
              <div className={style.imageContainer}>
                <div className={style.imageBox} style={{ backgroundImage: `url(${getImageUrl('images_large', item)})` }}></div>

                <img src={getImageUrl('images_large', item)} alt={item.name} className={style.image} />
                <div className={style.openInNewButton}>
                  <IconButton
                    color="primary"
                    // size="small"
                    onClick={openInNew}
                    style={{ color: '#FFFFFF' }}
                    aria-label="Open image in new window"
                  >
                    <OpenInNewIcon />
                  </IconButton>
                </div>
              </div>
            )}

            <div className={classnames(style.textPanel, item.hasImage ? '' : style.noImage)}>
              <div className={classnames(style.textRow, style.badges)}>
                {item.isNew && <div className={style.isNew}>New!</div>}
                {item.isRecommended && <div className={style.isRecommended}>Featured Plant!</div>}
              </div>

              <div className={style.textRow}>
                <Typography>Family</Typography>
                <Button color="primary" variant="outlined" className={style.familyButton} onClick={onClickFamily}>
                  {item.family.familyName}
                </Button>
              </div>
              <div className={style.textRow}>
                <Typography>Position</Typography>
                <Typography>{item.position.positionName}</Typography>
              </div>
              <div className={style.textRow}>
                <Typography>Soil Type</Typography>
                <Typography>{item.soil.soilName}</Typography>
              </div>
              <div className={style.textRow}>
                <Typography>Height</Typography>
                <Typography>{item.height}</Typography>
              </div>
              <div className={style.textRow}>
                <Typography>Min Temp</Typography>
                <Typography>{item.temp}</Typography>
              </div>
              <div className={classnames(style.textRow, style.description)}>
                <Typography>{item.description}</Typography>
              </div>
            </div>
          </div>
        </DialogContent>
      </MuiDialog>
    )
  );
};
