import { useMainStateActions } from 'stores/main/useMainState';
import { setSession } from 'utilities/localStorage';
import { EditMode, SnackbarMessageType, setEditMode } from 'stores/main';

export interface UseLogout {
  logout: VoidFunction;
}

export const useLogout = (): UseLogout => {
  const { clearUserToken, setSnackbar } = useMainStateActions();

  const logout = () => {
    clearUserToken();
    setEditMode(EditMode.View);
    setSession('userToken', null);
    setSnackbar('You are signed out', SnackbarMessageType.Success);
  };

  return { logout };
};
