import { RootState, useAppDispatch, useGet } from '..';
import {
  Resource,
  Section,
  SnackbarMessageType,
  clearUserToken,
  getState,
  setSections,
  setPage,
  setResources,
  setResourcesErrorContent,
  setResourcesIsDialogOpen,
  setResourcesIsSaving,
  setResourcesUploads,
  setSectionIndex,
  clearSectionIndex,
  setSnackbar,
  setUserToken,
  Upload,
  CookieConsent,
  setCookieConsent,
  Page,
  setPages,
  EditMode,
  setEditMode,
} from '.';

export { useGet as useGetMain };
export type { Page };

export const getCookieConsent = (state: RootState) => getState(state).cookieConsent;
export const getPages = (state: RootState) => getState(state).pages;
export const getPage = (state: RootState) => getState(state).page;
export const getSections = (state: RootState) => getState(state).content[getState(state).page];
export const getUserToken = (state: RootState) => getState(state).userToken;
export const getEditMode = (state: RootState) => getState(state).editMode;
export const getResources = (state: RootState) => getState(state).resources;
export const getResourcesIsSaving = (state: RootState) => getState(state).resourcesIsSaving;
export const getResourcesUploads = (state: RootState) => getState(state).resourcesUploads;
export const getResourcesErrorContent = (state: RootState) => getState(state).resourcesErrorContent;
export const getResourcesIsDialogOpen = (state: RootState) => getState(state).resourcesIsDialogOpen;
export const getSectionIndex = (state: RootState) => getState(state).sectionIndex as number;
export const getSnackbar = (state: RootState) => getState(state).snackbar;

export interface UseMainStateActions {
  setCookieConsent: (cookieConsent: CookieConsent) => void;
  setPages: (page: Page[]) => void;
  setPage: (page: string) => void;
  setSections: (sections: Section[]) => void;
  setUserToken: (username: string, token: string, role: string) => void;
  clearUserToken: VoidFunction;
  setEditMode: (value: EditMode) => void;
  setResources: (resources: Resource[]) => void;
  setResourcesIsSaving: (value: boolean) => void;
  setResourcesUploads: (resourcesUploads: Upload[]) => void;
  setResourcesErrorContent: (value: string) => void;
  setResourcesIsDialogOpen: (value: boolean) => void;
  setSectionIndex: (index: number) => void;
  setSnackbar: (message: string, messageType: SnackbarMessageType) => void;
  clearSectionIndex: VoidFunction;
}

export const useMainStateActions = (): UseMainStateActions => {
  const dispatch = useAppDispatch();

  return {
    setCookieConsent: (cookieConsent: CookieConsent) => dispatch(setCookieConsent(cookieConsent)),

    setPages: (pages: Page[]) => dispatch(setPages(pages)),

    setPage: (page: string) => dispatch(setPage(page)),

    setSections: (sections: Section[]) => dispatch(setSections(sections)),

    setUserToken: (username: string, token: string, role: string) => dispatch(setUserToken({ username, token, role })),

    clearUserToken: () => dispatch(clearUserToken()),

    setEditMode: (value: EditMode) => dispatch(setEditMode(value)),

    setResources: (resources: Resource[]) => dispatch(setResources(resources)),

    setResourcesIsSaving: (value: boolean) => dispatch(setResourcesIsSaving(value)),

    setResourcesUploads: (resourcesUploads: Upload[]) => dispatch(setResourcesUploads(resourcesUploads)),

    setResourcesErrorContent: (value: string) => dispatch(setResourcesErrorContent(value)),

    setResourcesIsDialogOpen: (value: boolean) => dispatch(setResourcesIsDialogOpen(value)),

    setSectionIndex: (index: number) => dispatch(setSectionIndex(index)),

    clearSectionIndex: () => dispatch(clearSectionIndex()),

    setSnackbar: (message: string, messageType: SnackbarMessageType) => dispatch(setSnackbar({ message, messageType })),
  };
};
