import { useState } from 'react';
import { useGetMain, getPages, useMainStateActions, Page, getUserToken } from 'stores/main/useMainState';
import { Direction } from './PageItem/PageItem';
import { savePages } from 'api';

export interface UsePageEditor {
  pages: Page[];
  isDialogOpen: boolean;
  canSave: boolean;
  onMove: (direction: Direction, id: number) => void;
  onAdd: () => void;
  onDelete: (id: number) => void;
  onDeleteCancel: VoidFunction;
  onDeleteConfirm: VoidFunction;
  onChange: (page: Page) => void;
  onSave: () => void;
}

export const usePageEditor = (): UsePageEditor => {
  const userToken = useGetMain(getUserToken);
  const { setPages } = useMainStateActions();
  const [deleteId, setDeleteId] = useState<number>();

  const pages = useGetMain(getPages).map((page, index) => ({
    ...page,
    tabOrder: index,
  }));

  const applyPages = (pages: Page[]) => {
    setPages(
      pages
        .sort((a, b) => a.tabOrder - b.tabOrder)
        .map((page, index) => ({
          ...page,
          tabOrder: index,
        }))
    );
  };

  const onMove = (direction: Direction, id: number) => {
    applyPages(
      pages.map((page) => ({
        ...page,
        tabOrder: page.id !== id ? page.tabOrder : page.tabOrder + direction,
      }))
    );
  };

  const onAdd = () => {
    const newPage: Page = {
      id: Math.min(0, Math.min(...pages.map((item) => item.id))) - 1,
      label: `Page ${pages.length + 1}`,
      url: `page${pages.length + 1}`,
      tabOrder: pages.length,
    };

    applyPages([...pages, newPage]);
  };

  const onDelete = (id: number) => {
    setDeleteId(id);
  };

  const onDeleteCancel = () => {
    setDeleteId(undefined);
  };

  const onDeleteConfirm = () => {
    applyPages(pages.filter((page) => page.id !== deleteId));
    setDeleteId(undefined);
  };

  const onChange = (changedItem: Page) => {
    applyPages(pages.map((item) => (item.id === changedItem.id ? { ...item, label: changedItem.label, url: changedItem.url } : item)));
  };

  const canSave = pages.length === new Set(pages.map((page) => page.url)).size && pages.every((page) => !!page.url || page.label === 'Home');

  const onSave = async (): Promise<void> => {
    await savePages(pages, userToken);
  };

  return {
    pages,
    isDialogOpen: !!deleteId,
    canSave,
    onMove,
    onAdd,
    onDelete,
    onDeleteConfirm,
    onDeleteCancel,
    onChange,
    onSave,
  };
};
