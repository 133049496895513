import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Icons } from 'components/common';

import styleOptions from '../styleOptions';
import { useButtons } from './useButtons';
import { createUseStyles } from 'react-jss';

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    button: {
      margin: '0.5rem',
    },
    lastButton: {
      margin: '0.5rem 2rem 0.5rem 0.5rem',
    },
    link: {
      display: 'flex',
      marginLeft: '2rem',
      alignItems: 'center',
    },
  },
};

const useStyles = createUseStyles((theme: any) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuItem: {},
}));

interface Props {
  addSection: any;
  onSave: any;
  onStyleApplied: any;
  isShowStyles: any;
  setIsShowStyles: any;
  role: any;
}

export const Buttons = (props: Props) => {
  const { addSection, onSave, onStyleApplied, isShowStyles, setIsShowStyles, role } = props;

  const { styleValue, onStyleSelected, onApplyStyle } = useButtons(styleOptions, onStyleApplied);

  const classes = useStyles({ theme: { spacing: (n: number) => `${n}rem` as any } });

  return (
    <div style={styles.root as any}>
      <Button style={styles.root.button} variant="contained" color="primary" onClick={addSection} size="large" startIcon={<Icons.AddCircle />}>
        Add New Section
      </Button>

      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="selectLabel">Style Options</InputLabel>
        <Select labelId="selectLabel" id="select" value={styleValue} onChange={onStyleSelected} label="Style Options">
          {styleOptions.map((s) => {
            return (
              <MenuItem className={classes.menuItem} key={s.value} value={s.value}>
                {s.value}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <Button style={styles.root.button} variant="contained" color="primary" onClick={onApplyStyle} disabled={!styleValue} size="small">
        Apply
      </Button>

      {role === 'admin' && (
        <FormControlLabel
          control={<Switch checked={isShowStyles} onChange={() => setIsShowStyles((prev: boolean) => !prev)} name="showStyles" color="primary" />}
          label="Edit Styles"
        />
      )}

      <Button style={styles.root.button} variant="contained" color="secondary" onClick={onSave} size="large">
        Save
      </Button>
    </div>
  );
};
