import React, { useEffect } from 'react';
import {
  getCount,
  getFamily,
  getLoadStatus,
  getPageNumber,
  getPlants,
  getPosition,
  getSearchType,
  getSoil,
  getText,
  useGetPlants,
  usePlantsStateActions,
} from 'stores/plants/usePlantsState';
import { getUserToken, useGetMain, useMainStateActions } from 'stores/main/useMainState';
import { Plant, SearchType } from 'stores/plants';
import { ADMIN_PAGE_SIZE, LoadStatus } from 'stores/constants';
import { deleteCatalogItem } from 'api';
import { SnackbarMessageType } from 'stores/main';
import { usePlantSearch } from '../usePlantSearch';

const getDefaultItem = () =>
  ({
    name: '',
    description: '',
    position: { positionId: 1 },
    soil: { soilId: 1 },
    genericName: '',
    hasImage: false,
    height: '',
    isActive: true,
    isNew: true,
    isRecommended: false,
    price: 0,
    family: { familyId: '' as unknown as number },
    temp: '',
  } as Plant);

export interface UseEditCatalog {
  plants: Plant[];
  pageNumber: number;
  count: number;
  pageSize: number;
  isConfirmDeleteDialogOpen: boolean;
  itemToDelete: Plant;
  header: string;
  loadStatus: LoadStatus;
  search: (overrides?: any) => Promise<void>;
  onChangePageNumber: (pageNumber: number) => void;
  onAddCatalogItem: VoidFunction;
  onShowDeleteConfirmation: (item: Plant) => void;
  onCancelDelete: VoidFunction;
  onConfirmDelete: (item: Plant) => Promise<void>;
}

export const useEditCatalog = (): UseEditCatalog => {
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = React.useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = React.useState<Plant>();

  const userToken = useGetMain(getUserToken);
  const { setSnackbar, setPage } = useMainStateActions();

  const plants = useGetPlants(getPlants);
  const text = useGetPlants(getText);
  const pageNumber = useGetPlants(getPageNumber);
  const count = useGetPlants(getCount);
  const soil = useGetPlants(getSoil);
  const position = useGetPlants(getPosition);
  const searchType = useGetPlants(getSearchType);
  const family = useGetPlants(getFamily);
  const loadStatus = useGetPlants(getLoadStatus);
  const { setPageNumber, setEditorDialogPlant, setIsEditorDialogOpen, setLoadStatus, setFamilies, setFamiliesLoadStatus } = usePlantsStateActions();

  const { execSearch } = usePlantSearch();

  let header = '';
  switch (searchType) {
    case SearchType.Family:
      header = `Family: ${family}`;
      break;
    case SearchType.Recommended:
      header = 'Featured items';
      break;
  }

  const search = async (overrides = {} as any) => {
    setLoadStatus(LoadStatus.Loading);
    await execSearch({
      searchType: SearchType.Search,
      pageNumber,
      pageSize: ADMIN_PAGE_SIZE,
      soil,
      position,
      text,
      includeInactive: true,
      ...overrides,
    });
    setLoadStatus(LoadStatus.Loaded);
  };

  const onChangePageNumber = async (pageNumber: number) => {
    setPageNumber(pageNumber);
    await search({ pageNumber });
  };

  const onAddCatalogItem = () => {
    setEditorDialogPlant(getDefaultItem());
    setIsEditorDialogOpen(true);
  };

  const onShowDeleteConfirmation = (item: Plant) => {
    setItemToDelete(item);
    setIsConfirmDeleteDialogOpen(true);
  };

  const onCancelDelete = () => {
    setItemToDelete({} as Plant);
    setIsConfirmDeleteDialogOpen(false);
  };

  const onConfirmDelete = async (item: Plant) => {
    setIsConfirmDeleteDialogOpen(false);
    setFamilies([]);
    setFamiliesLoadStatus(LoadStatus.Required);
    try {
      const request = {
        catalogId: item?.catalogId,
      };

      await deleteCatalogItem(request, userToken);
      setSnackbar('Item deleted', SnackbarMessageType.Success);
      await search();
    } catch (error) {
      console.error('ERROR: ', error);
      setSnackbar('Unable to delete item', SnackbarMessageType.Error);
    }
  };

  useEffect(() => {
    setPage('plants');
  }, [setPage]);

  return {
    plants,
    pageNumber,
    count,
    pageSize: ADMIN_PAGE_SIZE,
    isConfirmDeleteDialogOpen,
    itemToDelete: itemToDelete as Plant,
    header,
    loadStatus,
    search,
    onChangePageNumber,
    onAddCatalogItem,
    onShowDeleteConfirmation,
    onCancelDelete,
    onConfirmDelete,
  };
};
