import { getResources, getUserToken, useGetMain, useMainStateActions } from 'stores/main/useMainState';
import { Resource, SnackbarMessageType } from 'stores/main';
import { deleteResource as deleteResourceApi, getResources as getResourcesApi, getResourceUrl } from 'api';

export interface UseResourceList {
  resources: Resource[];
  apiUrl: string;
  deleteResource: (name: string) => Promise<void>;
  onCopyResourceToClipboard: (name: string) => void;
}

export const useResourceList = (): UseResourceList => {
  const resources = useGetMain(getResources);
  const userToken = useGetMain(getUserToken);

  const { setSnackbar, setResources } = useMainStateActions();

  const loadResources = async (): Promise<void> => {
    const resources = await getResourcesApi(userToken);
    setResources(resources);
  };

  const deleteResource = async (name: string) => {
    try {
      await deleteResourceApi(name, userToken);
      setSnackbar('Resource deleted', SnackbarMessageType.Success);
    } catch (error) {
      setSnackbar('Unable to delete resource', SnackbarMessageType.Error);
    }

    await loadResources();
  };

  const onCopyResourceToClipboard = (name: string) => {
    //    var textArea = document.createElement('textarea');
    const value = `![${name}]({{api}}/${encodeURI(name)})`;
    // textArea.value = value;
    // textArea.style.top = '0';
    // textArea.style.left = '0';
    // textArea.style.position = 'fixed';
    // document.body.appendChild(textArea);
    // textArea.focus();
    // textArea.select();

    // copy selected text to clipboard

    try {
      navigator.clipboard.writeText(value);
      //document.execCommand('copy');
    } catch (err) {}
    //    document.body.removeChild(textArea);
  };

  return { apiUrl: getResourceUrl(), resources, deleteResource, onCopyResourceToClipboard };
};
