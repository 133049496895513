import { useMainStateActions } from 'stores/main/useMainState';
import local from '../style.module.scss';
import { Section } from 'stores/main';

export interface UseViewer {
  selectedId: string | null;
  getEditClassName: () => string | null;
  isActive: (idx: number) => boolean;
  onSelectSection: (idx: number) => void;
}

export const useViewer = (sections: Section[], index?: number | null, setIndex?: (index: number) => void): UseViewer => {
  const isEdit = !!setIndex;
  const selectedId = (isEdit && sections && (index || index === 0) && sections[index] && sections[index].id) || null;

  const { clearSectionIndex } = useMainStateActions();

  const getEditClassName = () => {
    return isEdit ? local.editor : null;
  };

  const isActive = (idx: number) => {
    return isEdit && index === idx;
  };

  const onSelectSection = (idx: number) => {
    if (!isEdit) return;
    isActive(idx) ? clearSectionIndex() : setIndex(idx);
  };

  return { selectedId, getEditClassName, isActive, onSelectSection };
};
