import { useSnackbar } from 'notistack';
import { useGetMain, getSnackbar } from 'stores/main/useMainState';
import { useEffect } from 'react';

export const useSnackbarManager = () => {
  const snackbar = useGetMain(getSnackbar);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!enqueueSnackbar) return;

    const { message, messageType } = snackbar || {};
    if (message) {
      enqueueSnackbar(message, { variant: messageType || 'success' });
    }
  }, [snackbar, enqueueSnackbar]);
};
