import { Page, Resource, UserToken } from '../stores/main';
import { get, post, postNoResponse, deleteNoResponse, base } from './http';

const PAGE = '/api/Page';
const RESOURCE = '/api/Resource';
const LOGIN = '/api/Login';

export const getPages = async (): Promise<Page[]> => await get(`${PAGE}/pages`, '', null, 'Failed to load page');

export const getPage = async (page: string): Promise<any> => get(`${PAGE}`, `/${page}`, null, 'Failed to load page');

export const getResourceUrl = (): string => `${base}${RESOURCE}`;

export const getResources = async (token: UserToken): Promise<any> => get(`${RESOURCE}`, '', token, 'Failed to load resources');

export const login = async (username: string, password: string): Promise<any> => post(`${LOGIN}`, { username, password }, null, 'Failed to sign in');

export const beat = async (token: UserToken): Promise<any> => postNoResponse(`${LOGIN}/beat`, {}, token, 'Failed to beat');

export const savePage = async (page: string, sections: string, token: UserToken): Promise<any> =>
  postNoResponse(`${PAGE}/${page}`, { content: sections }, token, 'Failed to save page');

export const saveResources = (resources: Resource[], token: UserToken): Promise<any> => post(`${RESOURCE}`, resources, token, 'Failed to post resources');

export const deleteResource = (name: string, token: UserToken): Promise<any> =>
  deleteNoResponse(`${RESOURCE}/${name}`, '', token, 'Failed to delete resources');

export const savePages = (pages: Page[], token: UserToken): Promise<any> => post(`${PAGE}/pages`, { pages }, token, 'Failed to post pages');
