import axios, { AxiosResponse } from 'axios';

export type Token = {
  token: string;
};

export const base = process.env.NODE_ENV === 'development' ? `${process.env.REACT_APP_PROTOCOL}://localhost:${process.env.REACT_APP_PORT}` : '';

export const getApiUrl = () => `${base}/api/Resource`;

export const get = async <T,>(url: string, params: any, token: Token | null, errorMessage = 'GET error - no description available') => {
  try {
    const response = await axios.get(base + url + params, getHeader(token || undefined));
    checkStatus(response, errorMessage);
    return response.data as T;
  } catch {
    throw new Error(errorMessage);
  }
};

export const post = async <T,>(url: string, content: any, token: Token | null, errorMessage = 'POST error - no description available') => {
  const response = await axios.post(base + url, content, postHeader(token || undefined));
  checkStatus(response, errorMessage);
  return response.data as T;
};

export const postNoResponse = async (url: string, content: any, token: Token, errorMessage = 'POST error - no description available') => {
  const response = await axios.post(base + url, content, postHeader(token));
  return checkStatus(response, errorMessage);
};

export const deleteNoResponse = async (url: string, content: any, token: Token, errorMessage = 'DELETE error - no description available') => {
  const response = await axios.delete(base + url, deleteHeader(token));
  return checkStatus(response, errorMessage);
};

const getHeader = (token?: Token) => header(token, 'GET');
const postHeader = (token?: Token) => header(token, 'POST');
const deleteHeader = (token: Token) => header(token, 'DELETE');

const header = (token?: Token, method?: string) => ({
  method,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token?.token}`,
  },
});

const checkStatus = (response: AxiosResponse<any, any>, errorMessage: string) => {
  if (response.status !== 200) {
    throw new Error(errorMessage);
  }
};
