import React from 'react';
import MuiPaper from '@mui/material/Paper';

interface Props {
  elevation?: number;
  square?: boolean;
  style?: React.CSSProperties;
  children: React.ReactElement | React.ReactElement[];
}

export const Paper = (props: Props): React.ReactElement => {
  const { children, elevation, square = false, style } = props;

  return (
    <MuiPaper elevation={elevation} square={square} style={style}>
      {children}
    </MuiPaper>
  );
};
