import React from 'react';
import MuiFab from '@mui/material/Fab';
import MuiIconButton from '@mui/material/IconButton';

interface Props {
  children: React.ReactNode;
  color: 'primary' | 'secondary' | 'default' | 'inherit';
  ariaLabel?: string;
  disabled?: boolean;
  onClick: VoidFunction;
  fab?: boolean;
}

export const IconButton = (props: Props): React.ReactElement => {
  const { children, onClick, ariaLabel = 'Button', color = 'primary', fab = true, disabled = false } = props;

  const handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };

  return fab ? (
    <MuiFab color={color} onClick={handleClick} aria-label={ariaLabel} size="small" disabled={disabled}>
      {children}
    </MuiFab>
  ) : (
    <MuiIconButton color={color} onClick={handleClick} aria-label={ariaLabel} size="small" disabled={disabled}>
      {children}
    </MuiIconButton>
  );
};
