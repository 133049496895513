const replace = (_: unknown, getData: any) => {
  return { ...getData() };
};

const combine = (style: any, getData: () => any) => {
  const data = getData();
  const section = { ...data.section };
  const content = { ...data.content };

  delete data.section;
  delete data.content;

  const result = {
    ...style,
    section: {
      ...style.section,
      ...section,
    },
    content: {
      ...style.content,
      ...content,
    },
    ...data,
  };

  return result;
};

const styleOptions = [
  {
    value: 'Background Barley',
    getData: () => ({
      section: {
        background: '#ffdea0',
        marginTop: '1rem',
        marginBottom: '1rem',
      },
      color: '#000000',
      '& a': {
        color: '#2e86ab !important',
      },
      paddingTop: '1rem',
      paddingBottom: '1rem',
      fontWeight: 500,
    }),
    apply: combine,
  },
  {
    value: 'Background Blue',
    getData: () => ({
      section: {
        background: '#2e86ab',
      },
      color: '#ffffff',
      '& h1, & h2, & h3, & h4, & h5, & h6, & a': {
        color: '#ffffff !important',
      },
      paddingTop: '1rem',
      paddingBottom: '1rem',
    }),
    apply: combine,
  },
  {
    value: 'Background Green',
    getData: () => ({
      section: {
        background: '#89a988',
      },
      color: '#ffffff',
      '& h1, & h2, & h3, & h4, & h5, & h6, & a': {
        color: '#ffffff !important',
      },
      paddingTop: '1rem',
      paddingBottom: '1rem',
    }),
    apply: combine,
  },
  {
    value: 'Rounded Border - Bottom Only',
    getData: () => ({
      section: {
        borderRadius: '0 0 5rem 1rem',
      },
    }),
    apply: combine,
  },
  {
    value: 'Rounded Border - Top and Bottom',
    getData: () => ({
      section: {
        borderRadius: '5rem 1rem 5rem 1rem',
      },
    }),
    apply: combine,
  },
  {
    value: 'Rounded Border - Top Only',
    getData: () => ({
      section: {
        borderRadius: '5rem 1rem 0 0',
      },
    }),
    apply: combine,
  },
  {
    value: 'Slightly Rounded Border',
    getData: () => ({
      section: {
        borderRadius: '1rem',
      },
    }),
    apply: combine,
  },
  {
    value: 'Square Border',
    getData: () => ({
      section: {
        borderRadius: '0',
      },
    }),
    apply: combine,
  },
  {
    value: 'Images Left',
    getData: () => ({
      alignItems: 'flex-start',
    }),
    apply: combine,
  },
  {
    value: 'Images Centre',
    getData: () => ({
      alignItems: 'center',
    }),
    apply: combine,
  },
  {
    value: 'Images Right',
    getData: () => ({
      alignItems: 'flex-end',
    }),
    apply: combine,
  },
  {
    value: 'Table Background Grey',
    getData: () => ({
      '& table': {
        background: '#00000008',
        padding: '1rem',
        '& th, & td': {
          paddingRight: '1rem',
          paddingLeft: '1rem',
        },
      },
    }),
    apply: replace,
  },
  {
    value: 'Text Black',
    getData: () => ({
      color: '#000000',
      '& h1, & h2, & h3, & h4, & h5, & h6': {
        color: '#000000',
      },
    }),
    apply: combine,
  },
  {
    value: 'Text White',
    getData: () => ({
      color: '#ffffff',
      '& h1, & h2, & h3, & h4, & h5, & h6, & a': {
        color: '#ffffff',
      },
    }),
    apply: combine,
  },
  {
    value: 'Text Left',
    getData: () => ({
      '& p': {
        textAlign: 'left',
      },
    }),
    apply: combine,
  },
  {
    value: 'Text Justify',
    getData: () => ({
      '& p': {
        textAlign: 'justify',
      },
    }),
    apply: combine,
  },
  {
    value: 'Text Right',
    getData: () => ({
      '& p': {
        textAlign: 'right',
      },
    }),
    apply: combine,
  },
  {
    value: 'Reset to default',
    getData: () => ({
      section: {},
      content: {},
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      alignItems: 'flex-start',
    }),
    apply: replace,
  },
];

export default styleOptions;
