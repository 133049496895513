import React from 'react';
import MuiTabs from '@mui/material/Tabs';

interface Props {
  children: React.ReactElement | React.ReactElement[];
  value?: string | boolean;
  indicatorColor?: 'primary' | 'secondary';
  textColor?: 'primary' | 'secondary' | 'inherit';
}

export const Tabs = (props: Props): React.ReactElement => {
  const { children, value, indicatorColor, textColor } = props;
  return (
    <MuiTabs value={value} indicatorColor={indicatorColor} textColor={textColor}>
      {children}
    </MuiTabs>
  );
};
