import React from 'react';
import classnames from 'classnames';
import TextField from '@mui/material/TextField';
import { Section } from 'stores/main';
import { useInputFields } from './useInputFields';
import style from './style.module.scss';

const jsStyle = {
  root: {
    display: 'flex',
    textField: {
      width: '100%',
    },
  },
};

interface Props {
  section: Section;
  onMarkdownChanged: (value: string) => void;
  onStyleChanged: (value: string) => void;
  isShowStyles: boolean;
  page: string;
}

export const InputFields = (props: Props) => {
  const { section, onMarkdownChanged, onStyleChanged, isShowStyles, page } = props;

  const { markdownText, styleText, isStyleError, onMarkdownTextChanged, onStyleTextChanged } = useInputFields(section, page, onMarkdownChanged, onStyleChanged);

  return (
    <div className={style.inputFields} style={jsStyle.root}>
      <div className={style.input}>
        <TextField
          id="outlined-multiline-static"
          label="Markdown"
          multiline
          rows="15"
          variant="outlined"
          style={jsStyle.root.textField}
          value={markdownText}
          onChange={(e) => onMarkdownTextChanged(e.target.value)}
        />
      </div>
      {isShowStyles && (
        <div className={classnames(style.input, { [style.error]: isStyleError })}>
          <TextField
            id="outlined-multiline-static"
            label="Styles"
            multiline
            rows="15"
            variant="outlined"
            style={jsStyle.root.textField}
            value={styleText}
            onChange={(e) => onStyleTextChanged(e.target.value)}
          />
        </div>
      )}
    </div>
  );
};
