import { getEntireCatalog, useGetPlants, usePlantsStateActions } from 'stores/plants/usePlantsState';
import { SearchType } from 'stores/plants';
import { getPlants } from 'api';
import { useMainStateActions } from 'stores/main/useMainState';
import { SnackbarMessageType } from 'stores/main';
import { useEffect } from 'react';

export interface UseEntireCatalog {
  entireCatalog: any[];
  loadEntireCatalog: VoidFunction;
}

export const useEntireCatalog = (): UseEntireCatalog => {
  const { setPage, setSnackbar } = useMainStateActions();

  const entireCatalog = useGetPlants(getEntireCatalog);
  const { setEntireCatalog } = usePlantsStateActions();

  const loadEntireCatalog = async () => {
    try {
      const request = {
        searchType: SearchType.Search,
        pageNumber: 0,
        pageSize: 99999,
      };

      const { items } = await getPlants(request);
      setEntireCatalog(items);
    } catch (error) {
      console.error('ERROR: ', error);
      setSnackbar('Unable to load items. Please try again.', SnackbarMessageType.Error);
    }
  };

  useEffect(() => {
    setPage('catalogue');
  }, [setPage]);

  return {
    entireCatalog,
    loadEntireCatalog,
  };
};
