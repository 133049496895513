import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Fab from '@mui/material/Fab';
import { Upload as UploadType } from 'stores/main/index';
import { createUseStyles } from 'react-jss';
import { useUpload } from './useUpload';

enum FlexDirection {
  row = 'row',
}
const styles = {
  uploadItem: {
    display: 'flex',
    flexDirection: FlexDirection.row,
    marginTop: '1rem',
    alignItems: 'flex-end',
    color: 'white',
    uploadImage: {
      background: '#999',
      height: '4rem',
      width: '4rem',
      padding: '0.25rem',
      border: '1px solid white',
    },
    uploadTextField: {
      width: '20rem',
      minWidth: '20rem',
      margin: '1rem',
    },
  },
  uploadButton: {
    margin: '2rem 0 0 5.5rem',
    width: '5rem',
  },
  dropZone: {},
};

const useStyles = createUseStyles({
  button: {
    width: '15rem',
    padding: '0.5rem',
    marginTop: '1rem',
  },
});

const UploadImg = ({ upload }: { upload: UploadType }) => {
  return <img src={upload.content} style={styles.uploadItem.uploadImage} alt={'Resource'} />;
};

export const Upload = () => {
  const { saveResources, uploads, getRootProps, getInputProps, isDragActive, uploadNameChanged, onDelete, saveDisabled } = useUpload();
  const classes = useStyles();

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div style={styles.dropZone}>
          <Button color="primary" variant="contained" className={classes.button}>
            {isDragActive ? 'Drop resource file' : 'Upload resource'}
          </Button>
        </div>
      </div>
      <div>
        {uploads.length > 0 && (
          <>
            {uploads.map((upload, idx) => (
              <div key={`upload_${idx}`} style={styles.uploadItem}>
                <UploadImg upload={upload} />
                <TextField
                  value={upload.name}
                  label="Name"
                  style={styles.uploadItem.uploadTextField}
                  onChange={(e: any) => uploadNameChanged(idx, e.target.value)}
                />
                <Fab size="small" color="primary" aria-label="add" onClick={() => onDelete(idx)}>
                  <DeleteOutlineOutlinedIcon />
                </Fab>
              </div>
            ))}
            <Button variant="contained" color="primary" style={styles.uploadButton} disabled={saveDisabled} onClick={saveResources}>
              Save
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default Upload;
