import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import style from './style.module.scss';

export const Spinner = () => {
  return (
    <div className={style.spinner}>
      <CircularProgress />
    </div>
  );
};
