import { useNavigate } from 'react-router-dom';
import { Plant, Position, SearchType, Soil } from 'stores/plants';
import { useGetPlants, getDialogPlant, usePlantsStateActions, getIsDialogOpen } from 'stores/plants/usePlantsState';
import { usePlantSearch } from '../usePlantSearch';
import { getEditMode, useGetMain } from 'stores/main/useMainState';
import { ADMIN_PAGE_SIZE, PAGE_SIZE } from 'stores/constants';
import { getImageUrl } from 'utilities/imageUtils';
import { EditMode } from 'stores/main';

export interface UseDialog {
  item: Plant;
  isDialogOpen: boolean;
  closeDialog: VoidFunction;
  onClickFamily: VoidFunction;
  openInNew: VoidFunction;
}

export const useDialog = (): UseDialog => {
  const item = useGetPlants(getDialogPlant);
  const isDialogOpen = useGetPlants(getIsDialogOpen);
  const { setIsDialogOpen, setSearchType, setFamily, setText, setPageNumber } = usePlantsStateActions();

  const navigate = useNavigate();
  const { execSearch } = usePlantSearch();

  const editMode = useGetMain(getEditMode);
  const pageSize = editMode === EditMode.Plants ? ADMIN_PAGE_SIZE : PAGE_SIZE;

  const onClickFamily = async () => {
    const {
      family: { familyName },
    } = item!!;
    navigate(`/plants/family/${familyName}`);
    setSearchType(SearchType.Family);
    setFamily(familyName);
    setPageNumber(0);
    setText('');

    await execSearch({
      searchType: SearchType.Search,
      text: '',
      soil: Soil.All,
      position: Position.All,
      family: familyName,
      pageNumber: 0,
      pageSize,
      includeInactive: editMode === EditMode.Plants,
    });

    setIsDialogOpen(false);
  };

  const openInNew = () => {
    window.open(getImageUrl('images_large', item!!));
  };

  return { item: item!!, isDialogOpen, closeDialog: () => setIsDialogOpen(false), onClickFamily, openInNew };
};
