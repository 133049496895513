import { useState } from 'react';
import { useDebounce } from 'components/hooks/useDebounce';
import { Page } from 'stores/main';

export interface UsePageItem {
  label: string;
  url: string;
  onLabelChanged: (value: string) => void;
  onUrlChanged: (value: string) => void;
}

export const usePageItem = (id: number, propsLabel: string, propsUrl: string, onChange: (page: Page) => void): UsePageItem => {
  const [label, setLabel] = useState(propsLabel);
  const [url, setUrl] = useState(propsUrl);

  const debounce = useDebounce((item: Page) => {
    onChange(item);
  }, 1000);

  const onLabelChanged = (value: string) => {
    setLabel(value);
    debounce({ id, label: value, url });
  };

  const onUrlChanged = (value: string) => {
    setUrl(value);
    debounce({ id, label, url: value });
  };

  return {
    label,
    url,
    onLabelChanged,
    onUrlChanged,
  };
};
