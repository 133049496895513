import { RootState, useAppDispatch, useGet } from '..';
import {
  Plant,
  Position,
  SearchType,
  Soil,
  getState,
  setIsDialogOpen,
  setDialogPlant,
  setIsEditorDialogOpen,
  setEditorDialogPlant,
  setEntireCatalog,
  setFamilies,
  setFamiliesLoadStatus,
  setLoadStatus,
  setPageNumber,
  setPlants,
  setPosition,
  setSoil,
  setText,
  setSearchType,
  setFamily,
  Family,
} from '.';
import { LoadStatus } from '../constants';

export { useGet as useGetPlants };

export const getLoadStatus = (state: RootState) => getState(state).loadStatus;
export const getSearchType = (state: RootState) => getState(state).searchType;
export const getPlants = (state: RootState) => getState(state).plants;
export const getCount = (state: RootState) => getState(state).count;
export const getSoil = (state: RootState) => getState(state).soil;
export const getPosition = (state: RootState) => getState(state).position;
export const getText = (state: RootState) => getState(state).text;
export const getFamily = (state: RootState) => getState(state).family;
export const getPageNumber = (state: RootState) => getState(state).pageNumber;
export const getDialogPlant = (state: RootState) => getState(state).dialogPlant;
export const getIsDialogOpen = (state: RootState) => getState(state).isDialogOpen;
export const getEditorDialogPlant = (state: RootState) => getState(state).editorDialogPlant;
export const getIsEditorDialogOpen = (state: RootState) => getState(state).isEditorDialogOpen;
export const getFamilies = (state: RootState) => getState(state).families;
export const getFamiliesLoadStatus = (state: RootState) => getState(state).familiesLoadStatus;
export const getEntireCatalog = (state: RootState) => getState(state).entireCatalog;

export interface UsePlantsStateActions {
  setLoadStatus: (loadStatus: LoadStatus) => void;
  setSearchType: (searchType: SearchType) => void;
  setPlants: (items: Plant[], count: number) => void;
  setSoil: (soil: Soil) => void;
  setPosition: (position: Position) => void;
  setText: (text: string) => void;
  setFamily: (family: string) => void;
  setPageNumber: (pageNumber: number) => void;
  setDialogPlant: (plant: Plant) => void;
  setIsDialogOpen: (value: boolean) => void;
  setEditorDialogPlant: (plant: Plant) => void;
  setIsEditorDialogOpen: (value: boolean) => void;
  setFamilies: (families: Family[]) => void;
  setFamiliesLoadStatus: (loadStatus: LoadStatus) => void;
  setEntireCatalog: (plants: Plant[]) => void;
}

export const usePlantsStateActions = (): UsePlantsStateActions => {
  const dispatch = useAppDispatch();

  return {
    setLoadStatus: (loadStatus: LoadStatus) => dispatch(setLoadStatus(loadStatus)),
    setSearchType: (searchType: SearchType) => dispatch(setSearchType(searchType)),
    setPlants: (items: Plant[], count: number) => dispatch(setPlants({ items, count })),
    setSoil: (soil: Soil) => dispatch(setSoil(soil)),
    setPosition: (position: Position) => dispatch(setPosition(position)),
    setText: (text: string) => dispatch(setText(text)),
    setFamily: (family: string) => dispatch(setFamily(family)),
    setPageNumber: (pageNumber: number) => dispatch(setPageNumber(pageNumber)),
    setDialogPlant: (plant: Plant) => dispatch(setDialogPlant(plant)),
    setIsDialogOpen: (value: boolean) => dispatch(setIsDialogOpen(value)),
    setEditorDialogPlant: (plant: Plant) => dispatch(setEditorDialogPlant(plant)),
    setIsEditorDialogOpen: (value: boolean) => dispatch(setIsEditorDialogOpen(value)),
    setFamilies: (families: Family[]) => dispatch(setFamilies(families)),
    setFamiliesLoadStatus: (loadStatus: LoadStatus) => dispatch(setFamiliesLoadStatus(loadStatus)),
    setEntireCatalog: (plants: Plant[]) => dispatch(setEntireCatalog(plants)),
  };
};
