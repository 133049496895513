import React from 'react';
import { EditMode } from 'stores/main';
import { EditMenu } from 'components/Nav/EditMenu';
import { Page } from 'components/Page';
import { Editor } from 'components/Page/Editor';
import { PagesEditor } from 'components/Admin/PagesEditor';
import { EditCatalog } from 'components/Plants/EditCatalog';
import { Plants } from 'components/Plants';
import { usePagePicker } from './usePagePicker';

interface Props {
  page?: string;
  showEditMenu?: boolean;
}

export const PagePicker = (props: Props): React.ReactElement => {
  const { page, showEditMenu = true } = props;
  const { editMode, isLoggedIn } = usePagePicker(page);

  return isLoggedIn ? (
    <>
      {showEditMenu && <EditMenu />}
      {editMode === EditMode.View && <Page page={page} />}
      {editMode === EditMode.Content && <Editor />}
      {editMode === EditMode.View && page === 'plants' && <Plants />}
      {editMode === EditMode.Plants && page === 'plants' && <EditCatalog />}
      {editMode === EditMode.Pages && <PagesEditor />}
    </>
  ) : (
    <>
      <Page page={page} />
      {page === 'plants' && <Plants />}
    </>
  );
};
