import React from 'react';
import TextBox from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';

import classnames from 'classnames';
import { useSearchBar } from './useSearchBar';
import { Position, Soil } from 'stores/plants';
import style from './style.module.scss';

interface Props {
  className?: string;
}

export const SearchBar = (props: Props) => {
  const { className } = props;
  const { text, soil, position, family, isCatalogEdit, onTextChanged, onSoilChanged, onPositionChanged, onSearch, onSearchWithinFamily, onReset } =
    useSearchBar();

  return (
    <form onSubmit={onSearch} onReset={onReset} className={style.form}>
      <div className={classnames(style.searchBar, className)}>
        <div className={style.searchItem}>
          <TextBox label="Search" id="search-text" value={text} onChange={(e) => onTextChanged(e.target.value)} color="primary" variant="standard" />
        </div>

        <div className={style.searchItem}>
          <FormControl className={style.select}>
            <InputLabel id="soil-select-label" variant="standard">
              Soil Type
            </InputLabel>
            <Select
              labelId="soil-select"
              id="soil-select"
              value={soil}
              onChange={(e) => onSoilChanged(e.target.value as Soil)}
              color="primary"
              variant="standard"
            >
              <MenuItem value={0}>Show All</MenuItem>
              <MenuItem value={1}>Any</MenuItem>
              <MenuItem value={2}>Moist</MenuItem>
              <MenuItem value={3}>Well drained</MenuItem>
            </Select>
          </FormControl>

          <FormControl className={style.select}>
            <InputLabel id="position-select-label" variant="standard">
              Position
            </InputLabel>
            <Select
              labelId="position-select"
              id="soil-select"
              value={position}
              onChange={(e) => onPositionChanged(e.target.value as Position)}
              color="primary"
              variant="standard"
            >
              <MenuItem value={0}>Show All</MenuItem>
              <MenuItem value={1}>Any</MenuItem>
              <MenuItem value={2}>Part shade</MenuItem>
              <MenuItem value={3}>Shade</MenuItem>
              <MenuItem value={4}>Sun</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className={classnames(style.searchItem, style.buttons)}>
          <Button variant="contained" color="primary" type="submit" startIcon={<SearchIcon />}>
            {family ? <>All</> : <>Search</>}
          </Button>
          {family && (
            <Button variant="contained" color="primary" type="button" startIcon={<SearchIcon />} onClick={onSearchWithinFamily}>
              {family}
            </Button>
          )}
          <Button variant="outlined" color="primary" type="reset">
            {isCatalogEdit ? <>Featured</> : <>Reset</>}
          </Button>
        </div>
      </div>
    </form>
  );
};
