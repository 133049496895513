import React from 'react';
import uniqid from 'uniqid';
import { getSections, getPage, getSectionIndex, getUserToken, useGetMain, useMainStateActions } from 'stores/main/useMainState';
import { Section, SnackbarMessageType } from 'stores/main';
import { savePage as savePageApi } from 'api';

export interface UseEditor {
  page: string;
  sections: Section[];
  index: number | null;
  isShowStyles: boolean;
  role: string;
  onAddSection: VoidFunction;
  onDuplicateSection: VoidFunction;
  onDeleteSection: VoidFunction;
  onUp: (idx: number) => void;
  onDown: (idx: number) => void;
  onToggleDisabled: (idx: number) => void;
  onSave: VoidFunction;
  onStyleChanged: (value: string) => void;
  onStyleApplied: (getData: any, apply: any) => void;
  onMarkdownChanged: (value: string) => void;
  setSectionIndex: (value: number) => void;
  setIsShowStyles: (value: boolean) => void;
}

const newSection = () => ({
  id: uniqid(),
  markdown: '',
  style: JSON.stringify(
    {
      section: {},
      content: {},
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      alignItems: 'flex-start',
    },
    null,
    '\t'
  ),
});

export const useEditor = (): UseEditor => {
  const [isShowStyles, setIsShowStyles] = React.useState(false);

  const page = useGetMain(getPage);
  const index = useGetMain(getSectionIndex);
  const userToken = useGetMain(getUserToken);
  const sections = useGetMain(getSections) || [];

  const { setSections, setSectionIndex, clearSectionIndex, setSnackbar } = useMainStateActions();

  const onAddSection = () => {
    setSections([newSection(), ...sections]);
    setSectionIndex(0);
  };

  const onDuplicateSection = () => {
    const duplicateSection = { ...sections[index], id: uniqid() };
    setSections([...sections.slice(0, index), duplicateSection, ...sections.slice(index)]);
  };

  const onDeleteSection = () => {
    setSectionIndex(Math.max(0, index - 1));
    setSections(sections.filter((_, idx) => idx !== index));
  };

  const onUp = (idx: number) => {
    const copy = [...sections];
    const temp = copy[idx - 1];
    copy[idx - 1] = copy[idx];
    copy[idx] = temp;
    setSectionIndex(idx - 1);
    setSections(copy);
  };

  const onDown = (idx: number) => {
    const copy = [...sections];
    const temp = copy[idx + 1];
    copy[idx + 1] = copy[idx];
    copy[idx] = temp;
    setSections(copy);
    setSectionIndex(idx + 1);
  };

  const onToggleDisabled = (idx: number) => {
    const id = sections[idx].id;

    setSections(
      sections.map((s) =>
        s.id !== id
          ? s
          : {
              ...s,
              disabled: !s.disabled,
            }
      )
    );
  };

  const onMarkdownChanged = (value: string) => {
    setSections(
      sections.map((section, idx) => {
        return idx !== index ? section : { ...section, markdown: value };
      })
    );
  };

  const onStyleChanged = (value: string) => {
    setSections(
      sections.map((section, idx) => {
        return idx !== index ? section : { ...section, style: value };
      })
    );
  };

  const onStyleApplied = (getData: any, apply: any) => {
    const updatedSections = sections.map((section, idx) => {
      return idx !== index
        ? section
        : {
            ...section,
            style: JSON.stringify(apply(JSON.parse(section.style || '{}'), getData), null, '\t'),
          };
    });

    setSections(updatedSections);
  };

  const onSave = async () => {
    try {
      await savePageApi(page, JSON.stringify(sections), userToken);
      setSnackbar('Page saved', SnackbarMessageType.Success);
    } catch (error) {
      console.error('ERROR: ', error);
      setSnackbar('Unable to save page', SnackbarMessageType.Error);
    }
  };

  React.useEffect(() => {
    clearSectionIndex();
  }, [page]);

  return {
    page,
    sections,
    index,
    isShowStyles,
    role: userToken.role,
    onAddSection,
    onDuplicateSection,
    onDeleteSection,
    onUp,
    onDown,
    onToggleDisabled,
    onStyleChanged,
    onStyleApplied,
    onSave,
    onMarkdownChanged,
    setSectionIndex,
    setIsShowStyles,
  };
};
