import React from 'react';

import { Resources } from './Resources';
import { Buttons } from './Buttons';
import { useEditor } from './useEditor';
import { InputFields } from './InputFields';
import { Viewer } from '../Viewer';
import { Section } from 'stores/main';
import SearchTheme from 'styles/SearchTheme';

const jsStyle = {
  root: {
    background: 'linear-gradient(0deg, rgba(240,240,240,1) 0%, rgba(255,255,255,1) 100%)',
  },
};

export const Editor = (): React.ReactElement => {
  const {
    page,
    sections,
    index,
    isShowStyles,
    role,
    onAddSection,
    onDuplicateSection,
    onDeleteSection,
    onUp,
    onDown,
    onToggleDisabled,
    onSave,
    onStyleChanged,
    onStyleApplied,
    onMarkdownChanged,
    setSectionIndex,
    setIsShowStyles,
  } = useEditor();

  return (
    <div style={jsStyle.root}>
      <SearchTheme>
        <Resources />
        <Buttons
          addSection={onAddSection}
          onSave={onSave}
          onStyleApplied={onStyleApplied}
          isShowStyles={isShowStyles}
          setIsShowStyles={setIsShowStyles}
          role={role}
        />

        {sections.length > 0 && (
          <InputFields
            section={(index !== null && sections[index]) || ({} as Section)}
            onMarkdownChanged={onMarkdownChanged}
            onStyleChanged={onStyleChanged}
            isShowStyles={isShowStyles}
            page={page}
          />
        )}

        <Viewer
          sections={sections}
          index={index}
          setIndex={setSectionIndex}
          onUp={onUp}
          onDown={onDown}
          onDuplicate={onDuplicateSection}
          onDelete={onDeleteSection}
          onToggleDisabled={onToggleDisabled}
        />
      </SearchTheme>
    </div>
  );
};
