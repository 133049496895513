import { useEffect } from 'react';
import { useMainStateActions } from 'stores/main/useMainState';
import * as api from 'api';
import { setSession } from 'utilities/localStorage';
import { SnackbarMessageType } from 'stores/main';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

export interface UseSignIn {
  onSubmit: (formData: any) => void;
  control: any;
  reset: VoidFunction;
  Controller: any;
}

export const useSignIn = (): UseSignIn => {
  const { setPage, setUserToken, setSnackbar } = useMainStateActions();
  const navigate = useNavigate();

  const { handleSubmit, control, reset } = useForm();

  const login = async (username: string, password: string, navigate: any) => {
    try {
      const res = await api.login(username, password);
      setUserToken(username, res.token, res.role);
      setSession('userToken', {
        username,
        token: res.token,
        role: res.role,
      });

      setSnackbar('You are signed in', SnackbarMessageType.Success);
      navigate('/');
    } catch (error: any) {
      console.error('ERROR: ', error);
      setSnackbar(error.message, SnackbarMessageType.Error);
    }
  };

  const onSubmit = (formData: any) => {
    const { username, password } = formData;
    if (!username || !password) {
      return;
    }

    reset();
    login(username, password, navigate);

    return false;
  };

  useEffect(() => {
    setPage('signIn');
  }, [setPage]);

  return { onSubmit: handleSubmit(onSubmit), control, reset, Controller };
};
