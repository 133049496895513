import React from 'react';
import { Link } from 'react-router-dom';
import { Spinner } from 'components/common/Spinner';

import style from './style.module.scss';
import { useEntireCatalog } from './useEntireCatalog';

export const EntireCatalog = () => {
  const { entireCatalog, loadEntireCatalog } = useEntireCatalog();

  React.useEffect(() => {
    if (!entireCatalog) {
      loadEntireCatalog();
    }
  }, [entireCatalog, loadEntireCatalog]);

  if (!entireCatalog) {
    return <Spinner />;
  }

  return (
    <div className={style.page}>
      <h1>Norwell Nurseries</h1>
      <h2>Plant Catalogue</h2>

      {entireCatalog?.map((item, idx) => (
        <div key={`${item.catalogId}`}>
          <div className={idx % 2 === 0 ? style.row : style.rowAlt}>
            <div className={style.top}>
              <Link to={`/plants/${item.name}`} className={style.name}>
                {item.name}
              </Link>
              {item.genericName && <div>{` (${item.genericName})`}</div>}

              {item.family && (
                <Link to={`/plants/family/${item.family.familyName}`} className={style.family}>
                  {item.family.familyName}
                </Link>
              )}

              <div className={style.price}>£ {item.price.toFixed(2)}</div>
            </div>
            <div className={style.stats}>
              <div className={style.badges}>
                {item.isNew && <div className={style.isNew}>New!</div>}
                {item.isRecommended && <div className={style.isRecommended}>Featured!</div>}
              </div>

              <div>Position: {item.position?.positionName}</div>
              <div>Soil type: {item.soil?.soilName}</div>
              <div>Height: {item.height}</div>
              <div>Min. temp: {item.temp}°C</div>
            </div>
            <div className={style.description}>{item.description}</div>
          </div>

          <div className={style.rowPrint}>
            <div className={style.printName}>
              {item.name}
              {item.genericName ? ` (${item.genericName})` : null}
            </div>
            <div className={style.printFamily}>({item.family.familyName})</div>

            <div className={style.printPrice}>£ {item.price.toFixed(2)}</div>
            <div>Position: {item.position?.positionName}</div>
            <div>Soil type: {item.soil?.soilName}</div>
            <div>Height: {item.height}</div>
            <div className={style.printTemp}>Min. temp: {item.temp}°C</div>
            <div className={style.printDescription}>{item.description}</div>
          </div>
        </div>
      ))}
    </div>
  );
};
