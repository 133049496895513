import { AnyAction, combineReducers, configureStore, Reducer } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

// reducers
import main from './main';
import plants from './plants';

const combinedReducers = combineReducers({
  main,
  plants,
});

const rootReducer: Reducer = (state: any, action: AnyAction) => {
  return combinedReducers(state, action);
};

const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export { useSelector };

export type AppDispatch = typeof store.dispatch;

export type StateFn<T> = (state: RootState) => T;
export type EqualityFn = <T>(a: T, b: T) => boolean;
export const useGet = <T,>(fn: StateFn<T>, equalityFn?: EqualityFn): T => useSelector<T>(fn, equalityFn) as T;

export default store;
