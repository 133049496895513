import React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { TransitionProps } from '@mui/material/transitions';
import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import { Spinner } from 'components/common';
import { LoadStatus } from 'stores/constants';
import { useCatalogItemEditor } from './useCatalogItemEditor';
import { ConfirmDialog } from 'components/ConfirmDialog';
import { getImageUrl } from 'utilities/imageUtils';
import style from './style.module.scss';

const useStyles = createUseStyles({
  appBar: {
    position: 'relative',
    background: '#293132ff',
  },
  title: {
    marginLeft: '2rem',
  },
  textField: {
    width: '100%',
    color: 'white',
  },
  select: {
    width: '100%',
    color: 'white',
  },
  formControl: {
    width: '100%',
  },
  button: {
    width: '10rem',
    padding: '1rem',
    marginTop: '1rem',
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
  },
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface Props {
  onDeleteCatalogItem: (item: any) => void;
  onSearch: (overrides?: any) => void;
}

export const CatalogItemEditor = (props: Props): React.ReactElement => {
  const { onDeleteCatalogItem, onSearch } = props;

  const {
    loadStatus,
    item,
    isEditorDialogOpen,
    imageBase64,
    isDragActive,
    isDeleteImage,
    canSave,
    name,
    genericName,
    families,
    familyId,
    newFamilyName,
    positionId,
    soilId,
    height,
    temp,
    price,
    description,
    getRootProps,
    getInputProps,
    isConfirmDeleteDialogOpen,
    onChangeDeleteImage,
    onChangeName,
    onChangeGenericName,
    onChangeFamilyId,
    onChangeNewFamilyName,
    onChangePositionId,
    onChangeSoilId,
    onChangeHeight,
    onChangeTemp,
    onChangePrice,
    onChangeDescription,
    onShowConfirmDeleteDialog,
    onConfirmDelete,
    onSave,
    onClose,
  } = useCatalogItemEditor(onDeleteCatalogItem, onSearch);

  const classes = useStyles();

  if (loadStatus !== LoadStatus.Loaded) {
    return (
      <div className={style.progress}>
        <Spinner />
      </div>
    );
  }

  return (
    item && (
      <Dialog fullScreen open={isEditorDialogOpen} onClose={onClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar className={style.toolbar}>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <div className={style.title}>
              <Typography variant="h6" className={classes.title}>
                {item.name} {item.genericName ? ` (${item.genericName})` : null}
              </Typography>
              <Typography variant="h6" className={classes.title}>
                &pound; {item.price.toFixed(2)}
              </Typography>
            </div>
          </Toolbar>
        </AppBar>

        <div className={style.page}>
          {/* image (existing) */}
          {item.hasImage && !imageBase64 && (
            <div className={style.imageContainer}>
              <div className={style.imageBox} style={{ backgroundImage: `url(${getImageUrl('images_large', item)}` }}></div>

              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <img src={getImageUrl('images_large', item)} alt={item.name} className={style.image} />
              </div>
            </div>
          )}

          {/* uploaded image */}
          {imageBase64 && (
            <div className={style.imageContainer}>
              <div className={style.imageBox} style={{ backgroundImage: `url(${imageBase64})` }}></div>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <img src={imageBase64} alt={item.name} className={style.image} />
                <div className={style.imageReadyMessage}>
                  <CheckIcon />
                  Image ready to save
                </div>
              </div>
            </div>
          )}

          {/* Buttons */}
          <div className={style.content}>
            <div className={style.textPanel}>
              <div className={classnames(style.formRow, style.buttonRow)}>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Button color="primary" variant="contained" className={classes.button}>
                    {isDragActive ? 'Drop image' : 'Upload image'}
                  </Button>
                </div>
                <Button color="primary" variant="contained" className={classes.button} disabled={!canSave} onClick={onSave}>
                  Save
                </Button>
                <Button color="primary" variant="outlined" className={classes.button} onClick={onShowConfirmDeleteDialog} disabled={!item?.catalogId}>
                  Delete
                </Button>
              </div>

              {/* Delete Image */}
              {item.catalogId && (
                <div className={style.formRow}>
                  <FormControlLabel control={<Switch checked={isDeleteImage} onChange={onChangeDeleteImage} color="primary" />} label="Delete image" />
                </div>
              )}

              {/* Name */}
              <div className={style.formRow}>
                <TextField
                  error={!name.length}
                  label="Name"
                  value={name}
                  helperText={name.length > 0 ? null : 'Name is required'}
                  onChange={onChangeName}
                  className={classes.textField}
                  variant="standard"
                />
              </div>

              {/* Friendly name */}
              <div className={style.formRow}>
                <TextField label="Friendly name" value={genericName} onChange={onChangeGenericName} className={classes.textField} variant="standard" />
              </div>

              {/* Family */}
              <div className={classnames(style.formRow, style.familySelect)}>
                <FormControl className={classes.formControl} variant="standard" error={(familyId as unknown) === ''}>
                  <InputLabel id="family-label">Family</InputLabel>
                  <Select labelId="family-label" value={familyId} onChange={(e) => onChangeFamilyId(e.target.value as number)} className={classes.select}>
                    {/* Style Menu Item  */}
                    <MenuItem value={0}>Add new family</MenuItem>
                    {families.map(({ familyId, familyName }) => (
                      <MenuItem key={familyId} value={familyId}>
                        {familyName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              {/* New family name */}
              {familyId === 0 && (
                <div className={style.formRow}>
                  <TextField
                    label="New family name"
                    error={!newFamilyName.length}
                    value={newFamilyName}
                    onChange={onChangeNewFamilyName}
                    helperText={newFamilyName.length > 0 ? null : 'New family name is required'}
                    className={classes.textField}
                    variant="standard"
                  />
                </div>
              )}

              {/* Position */}
              <div className={style.formRow}>
                <FormControl className={classes.formControl} variant="standard">
                  <InputLabel id="position-label">Position</InputLabel>
                  <Select labelId="position-label" value={positionId} onChange={(e) => onChangePositionId(e.target.value as number)} className={classes.select}>
                    <MenuItem value={1}>Any</MenuItem>
                    <MenuItem value={2}>Part shade</MenuItem>
                    <MenuItem value={3}>Shade</MenuItem>
                    <MenuItem value={4}>Sun</MenuItem>
                  </Select>
                </FormControl>
              </div>

              {/* Soil */}
              <div className={style.formRow}>
                <FormControl className={classes.formControl} variant="standard">
                  <InputLabel id="soil-label">Soil</InputLabel>
                  <Select labelId="soil-label" value={soilId} onChange={(e) => onChangeSoilId(e.target.value as number)} className={classes.select}>
                    <MenuItem value={1}>Any</MenuItem>
                    <MenuItem value={2}>Moist</MenuItem>
                    <MenuItem value={3}>Well drained</MenuItem>
                  </Select>
                </FormControl>
              </div>

              {/* Height */}
              <div className={style.formRow}>
                <TextField
                  error={!height.length}
                  label="Height"
                  value={height}
                  helperText={height.length > 0 ? null : 'Height is required'}
                  onChange={onChangeHeight}
                  className={classes.textField}
                  variant="standard"
                />
              </div>

              {/* Min Temp */}
              <div className={style.formRow}>
                <TextField
                  error={!temp.length}
                  label="Minimum temperature"
                  value={temp}
                  helperText={temp.length > 0 ? null : 'Minimum temperature is required'}
                  onChange={onChangeTemp}
                  className={classes.textField}
                  variant="standard"
                />
              </div>

              {/* Price */}
              <div className={style.formRow}>
                <TextField
                  error={!price}
                  label="Price"
                  value={price}
                  helperText={price ? null : 'Price is required'}
                  onChange={onChangePrice}
                  type="number"
                  className={classnames(classes.textField, style.numeric)}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                  }}
                  variant="standard"
                />
              </div>

              {/* Description */}
              <div className={style.formRow}>
                <TextField
                  error={!description.length}
                  label="Description"
                  value={description}
                  helperText={description.length > 0 ? null : 'Description is required'}
                  onChange={onChangeDescription}
                  className={classes.textField}
                  multiline
                  rows={5}
                  variant="filled"
                />
              </div>
            </div>
          </div>
        </div>
        <ConfirmDialog
          title="Confirm Delete"
          text={'Are you sure you wish to delete this item?'}
          isOpen={isConfirmDeleteDialogOpen}
          onCancel={() => {
            onConfirmDelete(false);
          }}
          onConfirm={() => {
            onConfirmDelete(true);
          }}
        />
      </Dialog>
    )
  );
};
