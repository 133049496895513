import React from 'react';
import { Link } from 'react-router-dom';
import DrawerMui from '@mui/material/Drawer';
import ButtonMui from '@mui/material/Button';
import ListMui from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useDrawer } from './useDrawer';
import { ArrowForward, Instagram, Menu, Person, PersonOff, Twitter } from 'components/common/Icons';
import logo from 'assets/logo_40.png';
import style from './style.module.scss';

export const Drawer = () => {
  const { isOpen, isSignedIn, tabs, toggleDrawer, logout } = useDrawer();

  const list = (
    <div className={style.drawerList} role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer}>
      <ListMui>
        {tabs.map((t) => (
          <Link to={t.url} className={style.link} key={t.url}>
            <ListItem key={`tab${t.tabOrder}${t.url}`}>
              <ListItemIcon>
                <ArrowForward />
              </ListItemIcon>
              {t.label}
            </ListItem>
          </Link>
        ))}
      </ListMui>
      <Divider />
      <ListMui>
        <a href="https://www.twitter.com/NorwellNursery" target="_blank" rel="noreferrer" className={style.link}>
          <ListItem>
            <ListItemIcon>
              <Twitter />
            </ListItemIcon>
            Follow on Twitter
          </ListItem>
        </a>
        <a href="https://instagram.com/norwellnurseries" target="_blank" rel="noreferrer" className={style.link}>
          <ListItem>
            <ListItemIcon>
              <Instagram />
            </ListItemIcon>
            Follow on Instagram
          </ListItem>
        </a>
      </ListMui>
      <Divider />
      <ListMui>
        {isSignedIn ? (
          <div onClick={logout} className={style.link}>
            <ListItem>
              <ListItemIcon>
                <PersonOff />
              </ListItemIcon>
              Sign out
            </ListItem>
          </div>
        ) : (
          <Link to={'/signIn'} className={style.link}>
            <ListItem>
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              Sign in
            </ListItem>
          </Link>
        )}
      </ListMui>
    </div>
  );

  return (
    <div className={style.drawer}>
      <ButtonMui onClick={toggleDrawer}>
        <Menu style={{ fontSize: 32 }} color="primary" />
      </ButtonMui>

      <img src={logo} alt="logo" className={style.logo} />
      <h1 className={style.h1}>Norwell Nurseries</h1>

      <DrawerMui anchor="left" open={isOpen} onClose={toggleDrawer}>
        {list}
      </DrawerMui>
    </div>
  );
};
