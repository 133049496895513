import { PayloadAction, createReducer, createAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { LoadStatus } from '../constants';

export interface PlantsState {
  loadStatus: LoadStatus;
  searchType: SearchType;
  plants: Plant[];
  count: number;
  soil: number;
  position: number;
  text: string;
  family: string;
  pageNumber: number;
  dialogPlant: Plant | null;
  isDialogOpen: boolean;
  editorDialogPlant: Plant | null;
  isEditorDialogOpen: boolean;
  families: Family[];
  familiesLoadStatus: LoadStatus;
  entireCatalog: any;
}

export const getState = (state: RootState): PlantsState => state.plants;

export type Family = {
  familyId: number;
  familyName: string;
};

export type Plant = {
  catalogId: string;
  description: string;
  name: string;
  genericName: string;
  isNew: boolean;
  hasImage: boolean;
  price: number;
  family: Family;
  height: string;
  isActive: boolean;
  isRecommended: boolean;
  position: { positionId: number; positionName: string };
  soil: { soilId: number; soilName: string };
  temp: string;
  imageVersion: number;
};
export enum SearchType {
  Recommended = 'recommended',
  Search = 'search',
  Family = 'family',
  Plant = 'plant',
}
export type Plants = { items: Plant[]; count: number };

export enum Soil {
  All = 0,
  Any = 1,
  Moist = 2,
  WellDrained = 3,
}
export enum Position {
  All = 0,
  Any = 1,
  PartShade = 2,
  Shade = 3,
  Sun = 4,
}

export const setLoadStatus = createAction<LoadStatus>('plants/setLoadStatus');
export const setSearchType = createAction<SearchType>('plants/setSearchType');
export const setPlants = createAction<Plants>('plants/setPlants');
export const setSoil = createAction<Soil>('plants/setSoil');
export const setPosition = createAction<Position>('plants/setPosition');
export const setText = createAction<string>('plants/setText');
export const setFamily = createAction<string>('plants/setFamily');
export const setPageNumber = createAction<number>('plants/setPageNumber');
export const setDialogPlant = createAction<Plant>('plants/setDialogPlant');
export const setIsDialogOpen = createAction<boolean>('plants/setIsDialogOpen');
export const setEditorDialogPlant = createAction<Plant>('plants/setEditorDialogPlant');
export const setIsEditorDialogOpen = createAction<boolean>('plants/setIsEditorDialogOpen');
export const setFamilies = createAction<Family[]>('plants/setFamilies');
export const setFamiliesLoadStatus = createAction<LoadStatus>('plants/setFamiliesLoadStatus');
export const setEntireCatalog = createAction<Plant[]>('plants/setEntireCatalog');

const plantsState = createReducer<PlantsState>(
  {
    loadStatus: LoadStatus.Required,
    searchType: SearchType.Recommended,
    plants: [],
    count: 0,
    soil: Soil.All,
    position: Soil.All,
    text: '',
    family: '',
    pageNumber: 0,
    dialogPlant: null,
    isDialogOpen: false,
    editorDialogPlant: null,
    isEditorDialogOpen: false,
    families: [],
    familiesLoadStatus: LoadStatus.Required,
    entireCatalog: null,
  },
  (builder) => {
    builder.addCase(setLoadStatus, (state, action: PayloadAction<LoadStatus>) => {
      state.loadStatus = action.payload;
    });

    builder.addCase(setSearchType, (state, action: PayloadAction<SearchType>) => {
      state.searchType = action.payload;
    });

    builder.addCase(setPlants, (state, action: PayloadAction<Plants>) => {
      state.plants = action.payload.items;
      state.count = action.payload.count;
    });

    builder.addCase(setSoil, (state, action: PayloadAction<Soil>) => {
      state.soil = action.payload;
    });

    builder.addCase(setPosition, (state, action: PayloadAction<Position>) => {
      state.position = action.payload;
    });

    builder.addCase(setText, (state, action: PayloadAction<string>) => {
      state.text = action.payload;
    });

    builder.addCase(setFamily, (state, action: PayloadAction<string>) => {
      state.family = action.payload;
    });

    builder.addCase(setPageNumber, (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload;
    });

    builder.addCase(setDialogPlant, (state, action: PayloadAction<Plant>) => {
      state.dialogPlant = action.payload;
    });

    builder.addCase(setIsDialogOpen, (state, action: PayloadAction<boolean>) => {
      state.isDialogOpen = action.payload;
    });

    builder.addCase(setEditorDialogPlant, (state, action: PayloadAction<Plant>) => {
      state.editorDialogPlant = action.payload;
    });

    builder.addCase(setIsEditorDialogOpen, (state, action: PayloadAction<boolean>) => {
      state.isEditorDialogOpen = action.payload;
    });

    builder.addCase(setFamilies, (state, action: PayloadAction<Family[]>) => {
      state.families = action.payload;
    });

    builder.addCase(setFamiliesLoadStatus, (state, action: PayloadAction<LoadStatus>) => {
      state.familiesLoadStatus = action.payload;
    });

    builder.addCase(setEntireCatalog, (state, action: PayloadAction<Plant[]>) => {
      state.entireCatalog = action.payload;
    });
  }
);

export default plantsState;
