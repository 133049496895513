import React from 'react';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import classnames from 'classnames';
import { createUseStyles } from 'react-jss';

import SearchTheme from 'styles/SearchTheme';
import DialogTheme from 'styles/DialogTheme';
import { Plant } from 'stores/plants';
import { Pagination } from 'components/common';
import { SearchBar } from '../SearchBar';
import { ConfirmDialog } from 'components/ConfirmDialog';
import { CatalogItem } from './CatalogItem';
import { CatalogItemEditor } from './CatalogItemEditor';
import { useEditCatalog } from './useEditCatalog';
import style from './style.module.scss';

const useStyles = createUseStyles((theme: any) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    color: 'white',
  },

  fabAdd: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    color: 'white',
  },
}));

export const EditCatalog = (): React.ReactElement => {
  const {
    plants,
    pageNumber,
    count,
    pageSize,
    isConfirmDeleteDialogOpen,
    itemToDelete,
    header,
    onChangePageNumber,
    onAddCatalogItem,
    onShowDeleteConfirmation,
    onCancelDelete,
    onConfirmDelete,
    search,
  } = useEditCatalog();

  const classes = useStyles({ theme: { spacing: (n: number) => `${n}rem` as any } });

  return (
    <>
      <SearchTheme>
        <div>
          <SearchBar className={style.searchBar} />
          <Pagination pageNumber={pageNumber} count={count} pageSize={pageSize} changePageNumber={onChangePageNumber} className={style.pagination} />

          {header && <h4 className={style.header}>{header}</h4>}

          {plants.map((item: Plant) => (
            <CatalogItem key={item.catalogId} item={item} onDeleteCatalogItem={onShowDeleteConfirmation} />
          ))}
        </div>

        <Fab aria-label="Add new item" className={classnames(classes.fab, classes.fabAdd)} color="primary" onClick={onAddCatalogItem}>
          <AddIcon />
        </Fab>
      </SearchTheme>

      <DialogTheme>{<CatalogItemEditor onDeleteCatalogItem={onConfirmDelete} onSearch={search} />}</DialogTheme>

      <SearchTheme>
        <ConfirmDialog
          title="Confirm Delete"
          text={`Are you sure you wish to delete ${itemToDelete?.name ?? 'this item'}?`}
          isOpen={isConfirmDeleteDialogOpen}
          onCancel={onCancelDelete}
          onConfirm={() => onConfirmDelete(itemToDelete)}
        />
      </SearchTheme>
    </>
  );
};
