export enum SectionType {
  Paragraph = 'Paragraph',
  Twitter = 'twitter',
  Instagram = 'instagram',
}
type Identities = {
  [key: string]: string;
};

const identities: Identities = {
  [SectionType.Twitter]: '[twitter]@',
  [SectionType.Instagram]: '[instagram]@',
};

export type SectionDefinition = {
  sectionType: SectionType | 'Paragraph';
  id?: string;
};

export const combineStyles = (...rest: any[]) => {
  let val = {};
  rest.forEach((i) => Object.assign(val, i));
  return val;
};

export const getSectionDefinition = (source: string): SectionDefinition => {
  const sectionType = Object.values(SectionType).find((i) => source?.toLowerCase().indexOf(identities[i]) === 0);

  if (!sectionType) {
    return { sectionType: 'Paragraph' };
  }

  const id = source.substring(identities[sectionType].length);
  return { sectionType, id };
};
