import { Plant } from 'stores/plants';
import Compress from 'compress.js';

const defaultResizeImageOptions = {
  size: 2, // MB
  quality: 1, // JPG Quality
  maxWidth: 1200,
  maxHeight: 1200,
  resize: true,
};

export const resizeImage = async (file: File, options: any, callback: (arg0: { base64: string }) => void): Promise<void> => {
  const compress = new Compress();
  const resizedImage = await compress.compress([file], {
    ...defaultResizeImageOptions,
    ...options,
  });

  const img = resizedImage[0];
  // const base64str = img.data;
  // const imgExt = img.ext;
  // const resizedFile = Compress.convertBase64ToFile(base64str, imgExt);

  callback({
    base64: `${img.prefix}${img.data}`,
  });
};

export const getImageUrl = (path: string, plant: Plant): string => {
  const imageVersion = plant.imageVersion ? `_${plant.imageVersion}` : '';
  return `/${path}/${plant.catalogId}${imageVersion}.jpg`;
};
