import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getPage as getPageApi } from 'api';
import { getEditMode, getPage, getSections, getUserToken, useGetMain, useMainStateActions } from 'stores/main/useMainState';
import { EditMode, SnackbarMessageType } from 'stores/main';

export interface UsePagePicker {
  editMode: EditMode;
  isLoggedIn: boolean;
}
const noPage = ['signIn', 'catalogue'];

export const usePagePicker = (propsPage?: string): UsePagePicker => {
  const editMode = useGetMain(getEditMode);
  const isLoggedIn = !!useGetMain(getUserToken);
  const sections = useGetMain(getSections);

  const { setEditMode, setPage, setSnackbar, setSections } = useMainStateActions();

  useEffect(() => {
    if (propsPage !== 'plants' && editMode === EditMode.Plants) {
      setEditMode(EditMode.View);
    }
  }, [editMode, propsPage]);

  const { page: paramsPage } = useParams();
  const statePage = useGetMain(getPage);

  const page = paramsPage || propsPage || 'home';

  const loadSections = async (page: string) => {
    if (noPage.includes(page)) {
      return;
    }

    try {
      var sections = await getPageApi(page);
      setSections(JSON.parse(sections));
    } catch (error) {
      setSnackbar('Unable to load page', SnackbarMessageType.Error);
    }
  };

  useEffect(() => {
    if (statePage !== page) {
      setPage(page);
    }
  }, [page]);

  useEffect(() => {
    if (statePage && !sections) {
      loadSections(statePage);
    }
  }, [statePage]);

  return { editMode, isLoggedIn };
};
