import React from 'react';

export const useScrollToRef = (props: any) => {
  const scrollRef = React.useRef(null);

  React.useEffect(() => {
    if (!props) {
      return;
    }

    const timeout = setTimeout(() => {
      const ref = scrollRef.current;
      const bodyOffsetTop = document.body.getBoundingClientRect().top;
      const offsetTop = (scrollRef.current as any)?.getBoundingClientRect().top;

      if (!ref || !offsetTop) {
        return;
      }

      const top = offsetTop - bodyOffsetTop;

      try {
        window.scroll({ top, left: 0, behavior: 'smooth' });
      } catch {
        try {
          window.scrollTo(top, 0);
        } catch {
          (window as any).scrollTop = top;
        }
      }
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
  }, [props]);

  return scrollRef;
};
