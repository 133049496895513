import React from 'react';
import { Button, TextField } from '../common';
import MainTheme from 'styles/SearchTheme';
import { useSignIn } from './useSignIn';
import style from './style.module.scss';

export const SignIn = (): React.ReactElement => {
  const { onSubmit, control, reset, Controller } = useSignIn();

  return (
    <MainTheme>
      <form onSubmit={onSubmit}>
        <div className={style.signIn} style={{ margin: '2rem' }}>
          <Controller
            name={'username'}
            control={control}
            render={({ field: { onChange, value } }: any) => <TextField onChange={onChange} value={value || ''} label={'Username'} className={style.input} />}
          />
          <Controller
            name={'password'}
            control={control}
            render={({ field: { onChange, value } }: any) => (
              <TextField onChange={onChange} value={value || ''} type="password" label={'Password'} className={style.input} />
            )}
          />
          <Button type="submit" variant="contained" className={style.input}>
            Submit
          </Button>
          <Button onClick={() => reset()} variant="outlined">
            Reset
          </Button>
        </div>
      </form>
    </MainTheme>
  );
};
