import React from 'react';
import { getPages, getUserToken, useGetMain } from 'stores/main/useMainState';
import { useLogout } from 'components/hooks/useLogout';

export interface UseDrawer {
  isOpen: boolean;
  isSignedIn: boolean;
  tabs: { label: string; tabOrder: number; url: string }[];
  toggleDrawer: VoidFunction;
  logout: VoidFunction;
}

export const useDrawer = (): UseDrawer => {
  const [isOpen, setIsOpen] = React.useState(false);

  const token = useGetMain(getUserToken);
  const isSignedIn = !!token;
  const { logout } = useLogout();

  const tabs = useGetMain(getPages)
    .filter(({ label }) => !!label)
    .map(({ url, label, tabOrder }) => ({
      label,
      tabOrder,
      url: `/${url ?? ''}`,
    }));

  const toggleDrawer = (event = {} as any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setIsOpen(!isOpen);
  };

  return { isOpen, isSignedIn, tabs, toggleDrawer, logout };
};
