const colors = {
  themeMono0: '#000000',
  themeMono2: '#293132',
  themeMono4: '#444444',
  themeMonoC: '#ccc',
  themeMonoF: '#fff',
  themeColor1: '#89a988',
  themeColor2: '#0a5e42',
  themeColor3: '#293132',
  themeColor4: '#2e86ab',
  themeColor5: '#6c91bf',
  themeColor6: '#880000',
  themeColor7: '#a7cba5',
  themeColor8: '#fffcb2',
  themeColor9: '#6e886e',
  themeColor10: '#C06060',
  themeColor11: '#c60202',
  themeColor12: '#ffc90c',
};

export default colors;
