import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { InstagramEmbed } from 'react-social-media-embed';
import classnames from 'classnames';

import { getApiUrl } from 'api';
import { SectionType, getSectionDefinition } from '../utils';
import { CookieConsent, EditMode, Section } from 'stores/main';
import { getCookieConsent, getEditMode, useGetMain } from 'stores/main/useMainState';
import { SectionEditButtons } from './SectionEditButtons';
import { useViewer } from './useViewer';
import { StyledMarkdown } from './SectionEditButtons/StyledMarkdown';

import local from '../style.module.scss';

interface Props {
  sections: Section[];
  index?: number | null;

  setIndex?: (index: number) => void;
  onUp?: (index: number) => void;
  onDown?: (index: number) => void;
  onDuplicate?: (index: number) => void;
  onDelete?: (index: number) => void;
  onToggleDisabled?: (index: number) => void;
}

export const Viewer = (props: Props) => {
  const { sections, index, setIndex, onUp, onDown, onDuplicate, onDelete, onToggleDisabled } = props;

  const apiUrl = getApiUrl();
  const cookieConsent = useGetMain(getCookieConsent);
  const editMode = useGetMain(getEditMode);

  const { selectedId, getEditClassName, isActive, onSelectSection } = useViewer(sections, index, setIndex);

  return (
    <div className={local.page}>
      {sections?.length
        ? sections.map((section, idx) => {
            if (editMode === EditMode.View && section.disabled) return null;

            const source = section.markdown.replaceAll('{{api}}', apiUrl);
            const style = section.style.length ? JSON.parse(section.style.replaceAll('{{api}}', apiUrl)) : '';
            const { sectionType, id } = getSectionDefinition(source);

            return (
              <div
                key={section.id}
                className={classnames(local.section, getEditClassName(), selectedId === section.id && local.isActive, section.disabled && local.disabled)}
                style={style.section}
                onClick={() => onSelectSection(idx)}
              >
                {isActive(idx) && (
                  <SectionEditButtons
                    canUp={index !== 0}
                    canDown={idx < sections.length - 1}
                    disabled={!!section.disabled}
                    onUp={() => onUp?.(idx)}
                    onDown={() => onDown?.(idx)}
                    onDuplicate={() => onDuplicate?.(idx)}
                    onDelete={() => onDelete?.(idx)}
                    onToggleDisabled={() => onToggleDisabled?.(idx)}
                  />
                )}

                <div className={classnames(local.content, section.disabled && local.disabled)} style={style.content}>
                  {/* Twitter */}
                  {cookieConsent === CookieConsent.Accepted && sectionType === SectionType.Twitter && (
                    <TwitterTimelineEmbed sourceType="profile" screenName={id} options={style.twitter} {...style.twitter} />
                  )}

                  {/* Instagram */}
                  {cookieConsent === CookieConsent.Accepted && sectionType === SectionType.Instagram && <InstagramEmbed url={id} {...style.instagram} />}

                  {/* Markdown */}
                  {sectionType === SectionType.Paragraph && <StyledMarkdown style={style} source={source} />}
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
};
