import { useState } from 'react';

export interface UseSectionEditButtons {
  showDialog: boolean;
  onDelete: VoidFunction;
  onDeleteConfirm: VoidFunction;
  onDeleteCancel: VoidFunction;
}

export const useSectionEditButtons = (onDeleteProps: VoidFunction): UseSectionEditButtons => {
  const [showDialog, setShowDialog] = useState(false);

  const onDelete = () => {
    setShowDialog(true);
  };

  const onDeleteConfirm = () => {
    onDeleteProps();
    setShowDialog(false);
  };

  const onDeleteCancel = () => {
    setShowDialog(false);
  };

  return { showDialog, onDelete, onDeleteConfirm, onDeleteCancel };
};
