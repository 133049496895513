import React from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Tooltip from '@mui/material/Tooltip';
import { Dialog } from './Dialog';
import { SearchBar } from './SearchBar';
import { Pagination } from '../common';
import SearchTheme from 'styles/SearchTheme';
import DialogTheme from 'styles/DialogTheme';
import { useScrollToRef } from '../hooks/useScrollToRef';
import { useScrollToTop } from '../hooks/useScrollToTop';
import { ReactComponent as NewIcon } from 'assets/new.svg';
import colors from 'styles/colors';
import style from './style.module.scss';
import { usePlants } from './usePlants';
import { Plant } from 'stores/plants';
import { LoadStatus } from 'stores/constants';
import { createUseStyles } from 'react-jss';
import { getImageUrl } from 'utilities/imageUtils';

const useStyles = createUseStyles({
  root: {
    background: '#89a98840',
    palette: {
      ...colors,
    },
  },
  media: {
    height: '8.75rem',
  },
});

export const Plants = () => {
  const { loadStatus, count, pageSize, pageNumber, family, isRecommended, heading, plants, text, soil, position, onPlantClick, onChangePageNumber } =
    usePlants();

  const classes = useStyles();
  const scrollRef = useScrollToRef(pageNumber);
  const scrollToTop = useScrollToTop(family);

  return (
    <>
      <div className={style.page} ref={scrollToTop}>
        <SearchTheme>
          <SearchBar />

          {!isRecommended && (
            <div className={style.heading}>
              <Pagination pageNumber={pageNumber} count={count} pageSize={pageSize} changePageNumber={onChangePageNumber} />
            </div>
          )}
        </SearchTheme>

        <div ref={scrollRef} />

        {heading && (
          <div className={style.heading}>
            <h4>{heading}</h4>
          </div>
        )}

        {loadStatus === LoadStatus.Loaded && (
          <div className={style.plantContainer}>
            {plants?.length ? (
              plants.map((plant: Plant) => {
                return (
                  <div className={style.card} key={plant.catalogId}>
                    {plant.isNew && (
                      <div className={style.newIcon}>
                        <Tooltip title="New!" placement="bottom-end">
                          <NewIcon />
                        </Tooltip>
                      </div>
                    )}
                    <Card className={classes.root}>
                      <CardActionArea onClick={() => onPlantClick(plant)}>
                        {plant.hasImage ? (
                          <CardMedia className={classes.media} image={getImageUrl('images', plant)} title={plant.name} />
                        ) : (
                          <div className={style.awaitingImage}>Awaiting image</div>
                        )}

                        <CardContent>
                          <div className={style.title}>
                            <div>{plant.name}</div>
                            <p>{plant.genericName}</p>
                          </div>
                        </CardContent>
                      </CardActionArea>
                      <CardActions>
                        <div className={style.price}>{(plant.price || plant.price === 0) && `£ ${plant.price.toFixed(2)}`}</div>
                      </CardActions>
                    </Card>
                  </div>
                );
              })
            ) : (
              <div className={style.noResults}>
                <p>
                  There are no matching results for '{text}'{soil || position ? ` for the selected soil type and position` : null}.
                </p>
                <p>Please try again using part of the word, e.g. "Chrysanth" or "Chrysanthemum".</p>
                <p>You do not need to use plurals.</p>
              </div>
            )}
          </div>
        )}
        {!isRecommended && (
          <div className={style.lowerPagination}>
            <SearchTheme>
              <Pagination
                pageNumber={pageNumber}
                count={count}
                pageSize={pageSize}
                changePageNumber={onChangePageNumber}
                showPages={false}
                className={style.lowerPagination}
              />
            </SearchTheme>
          </div>
        )}
      </div>

      <DialogTheme>
        <Dialog />
      </DialogTheme>
    </>
  );
};
