import React from 'react';

export const useScrollToTop = (props: any) => {
  const scrollRef = React.useRef(null);

  React.useEffect(() => {
    const ref = scrollRef.current;
    if (!ref) {
      return;
    }

    try {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    } catch {
      try {
        window.scrollTo(0, 0);
      } catch {
        (window as any).scrollTop = 0;
      }
    }
  }, [props]);

  return scrollRef;
};
