import { ThemeProvider, createTheme } from '@mui/material/styles';

import colors from './colors';

const theme = createTheme({
  spacing: 16,
  typography: {
    fontFamily: '"Roboto", "sans-serif"',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightBold: 500,
    fontSize: 15,
  },
  palette: {
    ...colors,
    error: {
      main: colors.themeColor10,
    },
    background: {
      paper: colors.themeMono4,
    },
    text: {
      primary: colors.themeMonoC,
      secondary: colors.themeMono2,
    },
    primary: {
      main: colors.themeColor4,
    },
  },
});

const DialogTheme = ({ children }: { children: any }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default DialogTheme;
