import React from 'react';
import { getPageNumber, getPosition, getSoil, getText, useGetPlants, usePlantsStateActions } from 'stores/plants/usePlantsState';
import { Position, SearchType, Soil } from 'stores/plants';
import { ADMIN_PAGE_SIZE, PAGE_SIZE } from 'stores/constants';
import { usePlantSearch } from '../usePlantSearch';
import { useNavigate, useParams } from 'react-router-dom';
import { getEditMode, useGetMain } from 'stores/main/useMainState';
import { EditMode } from 'stores/main';

export interface UseSearchBar {
  text: string;
  soil: Soil;
  position: Position;
  family: string;
  isCatalogEdit: boolean;
  onTextChanged: (value: string) => void;
  onSoilChanged: (value: Soil) => void;
  onPositionChanged: (value: Position) => void;
  onSearch: (e: React.FormEvent<HTMLFormElement>) => void;
  onReset: VoidFunction;
  onSearchWithinFamily: VoidFunction;
}

export const useSearchBar = (): UseSearchBar => {
  const text = useGetPlants(getText);
  const soil = useGetPlants(getSoil);
  const position = useGetPlants(getPosition);
  const pageNumber = useGetPlants(getPageNumber);
  const editMode = useGetMain(getEditMode);
  const { family } = useParams();

  const { setText, setSoil, setPosition, setFamily, setSearchType, setPageNumber } = usePlantsStateActions();
  const { execSearch } = usePlantSearch();

  const navigate = useNavigate();
  const pageSize = editMode === EditMode.Plants ? ADMIN_PAGE_SIZE : PAGE_SIZE;

  const onSearch = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    navigate(`/plants`);
    setFamily('');
    setSearchType(SearchType.Search);
    setPageNumber(0);

    await execSearch({
      searchType: SearchType.Search,
      pageNumber: 0,
      pageSize,
      soil,
      position,
      text,
      family: '',
      includeInactive: editMode === EditMode.Plants,
    });
  };

  const onSearchWithinFamily = async () => {
    navigate(`/plants/family/${family}`);
    setFamily(family!!);
    setSearchType(SearchType.Search);
    setPageNumber(0);

    await execSearch({
      searchType: SearchType.Search,
      pageNumber,
      pageSize,
      soil,
      position,
      text,
      family,
      includeInactive: editMode === EditMode.Plants,
    });
  };

  const onReset = async () => {
    navigate(`/plants`);
    setSearchType(SearchType.Recommended);
    setText('');
    setSoil(Soil.All);
    setPosition(Position.All);
    setFamily('');
    setPageNumber(0);

    await execSearch({
      searchType: SearchType.Recommended,
      text: '',
      soil: Soil.All,
      position: Position.All,
      family: '',
      pageNumber: 0,
      pageSize: 99999,
      includeInactive: editMode === EditMode.Plants,
    });
  };

  return {
    text,
    soil,
    position,
    family: family!!,
    isCatalogEdit: editMode === EditMode.Plants,
    onTextChanged: setText,
    onSoilChanged: setSoil,
    onPositionChanged: setPosition,
    onSearch,
    onSearchWithinFamily,
    onReset,
  };
};
