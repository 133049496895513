import React from 'react';
import Button from '@mui/material/Button';
import { ConfirmDialog } from 'components/ConfirmDialog';
import DialogTheme from 'styles/DialogTheme';
import style from './style.module.scss';
import { useEditMenu } from './useEditMenu';

export const EditMenu = (): React.ReactElement | null => {
  const {
    isVisible,
    isConfirmNewDialogOpen,
    isConfirmRecommendedDialogOpen,
    onShowConfirmNew,
    onShowConfirmRecommended,
    onCancelConfirmNew,
    onCancelConfirmRecommended,
    onResetNew,
    onResetRecommended,
  } = useEditMenu();

  return isVisible ? (
    <div className={style.editMenu}>
      <DialogTheme>
        <Button onClick={onShowConfirmNew} variant="contained" size="small" color="primary" className={style.button}>
          Clear NEW status
        </Button>

        <Button onClick={onShowConfirmRecommended} variant="contained" size="small" color="primary" className={style.button}>
          Clear FEATURED status
        </Button>

        <ConfirmDialog
          title="Confirm Reset"
          text={
            <>
              Are you sure you wish to remove NEW status from all items?
              <br />
              <br />
              This action cannot be undone.
            </>
          }
          isOpen={isConfirmNewDialogOpen}
          onConfirm={onResetNew}
          onCancel={onCancelConfirmNew}
        />
        <ConfirmDialog
          title="Confirm Reset"
          text={
            <>
              Are you sure you wish to remove FEATURED status from all items?
              <br />
              <br />
              This action cannot be undone.
            </>
          }
          isOpen={isConfirmRecommendedDialogOpen}
          onConfirm={onResetRecommended}
          onCancel={onCancelConfirmRecommended}
        />
      </DialogTheme>
    </div>
  ) : null;
};

export default EditMenu;
