import React from 'react';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import classnames from 'classnames';
import style from './style.module.scss';

interface Props {
  pageNumber: number;
  count: number;
  pageSize: number;
  changePageNumber: (pageNumber: number) => void;
  className?: string;
  showPages?: boolean;
}

export const Pagination = (props: Props) => {
  const { pageNumber, count, pageSize, changePageNumber, className, showPages = true } = props;

  const lastPage = Math.floor((count - 1) / pageSize);
  if (lastPage === 0) {
    return null;
  }

  const pageButtons = [];
  for (let p = 0; p <= lastPage; p++) {
    pageButtons.push(
      <Fab
        key={`page_button_${p}`}
        size="small"
        color="primary"
        disabled={p === pageNumber}
        aria-label="page"
        style={{ margin: '0.2rem' }}
        onClick={() => changePageNumber(p)}
      >
        {p + 1}
      </Fab>
    );
  }

  return pageButtons.length === 0 ? null : (
    <div className={classnames(style.pagination, className)}>
      <IconButton aria-label="back" disabled={pageNumber === 0} onClick={() => changePageNumber(pageNumber - 1)}>
        <ChevronLeftIcon fontSize="small" />
      </IconButton>
      {showPages && pageButtons}
      <IconButton aria-label="next" disabled={pageNumber === lastPage} onClick={() => changePageNumber(pageNumber + 1)}>
        <ChevronRightIcon fontSize="small" />
      </IconButton>
    </div>
  );
};
