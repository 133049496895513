import { useEffect } from 'react';
import { ADMIN_PAGE_SIZE, LoadStatus, PAGE_SIZE } from 'stores/constants';
import { Plant, Position, SearchType, Soil } from 'stores/plants';
import {
  getCount,
  getLoadStatus,
  getPageNumber,
  getPlants,
  getPosition,
  getSearchType,
  getSoil,
  getText,
  useGetPlants,
  usePlantsStateActions,
} from 'stores/plants/usePlantsState';
import { getEditMode, useGetMain } from 'stores/main/useMainState';
import { usePlantSearch } from './usePlantSearch';
import { useParams } from 'react-router-dom';
import { EditMode } from 'stores/main';

export interface UsePlants {
  loadStatus: LoadStatus;
  plants: Plant[];
  count: number;
  text: string;
  soil: Soil;
  position: Position;
  family: string;
  isRecommended: boolean;
  pageNumber: number;
  pageSize: number;
  heading: string;
  onPlantClick: (plant: Plant) => void;
  onChangePageNumber: (page: number) => void;
}

export const usePlants = (): UsePlants => {
  const loadStatus = useGetPlants(getLoadStatus);

  const { family: urlFamily, plant: urlPlant } = useParams();
  const plants = useGetPlants(getPlants);
  const count = useGetPlants(getCount);
  const isRecommended = useGetPlants(getSearchType) === SearchType.Recommended;
  const pageNumber = useGetPlants(getPageNumber);
  const editMode = useGetMain(getEditMode);
  const soil = useGetPlants(getSoil);
  const position = useGetPlants(getPosition);
  const text = useGetPlants(getText);

  const { setDialogPlant, setIsDialogOpen, setPageNumber, setFamily, setSearchType } = usePlantsStateActions();
  const { execSearch } = usePlantSearch();

  const heading = isRecommended ? (count > 0 ? 'Featured plants' : '') : urlFamily ? `Family: ${urlFamily}` : urlPlant ? `${urlPlant}` : 'Results';
  const pageSize = editMode === EditMode.Plants ? ADMIN_PAGE_SIZE : PAGE_SIZE;

  useEffect(() => {
    setFamily(urlFamily ?? '');
    if (urlFamily) {
      setSearchType(SearchType.Family);
      (async () =>
        await execSearch({
          searchType: SearchType.Family,
          pageNumber,
          pageSize,
          soil,
          position,
          text,
          family: urlFamily,
          includeInactive: editMode === EditMode.Plants,
        }))();
    }
  }, [urlFamily]);

  useEffect(() => {
    if (urlPlant) {
      setSearchType(SearchType.Plant);
      (async () =>
        await execSearch({
          searchType: SearchType.Plant,
          pageNumber: 0,
          pageSize,
          soil: Soil.All,
          position: Position.All,
          text: urlPlant,
          family: '',
          includeInactive: editMode === EditMode.Plants,
        }))();
    }
  }, [urlPlant]);

  useEffect(() => {
    if (loadStatus !== LoadStatus.Required || urlFamily || urlPlant) return;

    (async () =>
      await execSearch({
        searchType: SearchType.Recommended,
        pageNumber,
        pageSize: 99999,
        soil,
        position,
        text,
        family: '',
        includeInactive: editMode === EditMode.Plants,
      }))();
  }, [loadStatus]);

  const onPlantClick = (plant: Plant) => {
    setDialogPlant(plant);
    setIsDialogOpen(true);
  };

  const onChangePageNumber = async (pageNumber: number) => {
    setPageNumber(pageNumber);
    await execSearch({
      searchType: SearchType.Search,
      pageNumber,
      pageSize,
      soil,
      position,
      text,
      family: urlFamily ?? '',
      includeInactive: editMode === EditMode.Plants,
    });
  };

  return {
    loadStatus,
    count,
    pageSize,
    pageNumber,
    family: urlFamily ?? '',
    isRecommended,
    heading,
    plants,
    soil,
    position,
    text,
    onPlantClick,
    onChangePageNumber,
  };
};
