import React from 'react';
import { Spinner } from 'components/common';
import { Viewer } from './Viewer';
import { getSections, useGetMain } from 'stores/main/useMainState';

interface Props {
  page?: string;
}

export const Page = (props: Props): React.ReactElement => {
  const sections = useGetMain(getSections);

  if (!sections) {
    return <Spinner />;
  }

  return <Viewer sections={sections} />;
};
