import React from 'react';
import { getUserToken, useGetMain } from 'stores/main/useMainState';
import { beat as beatApi } from 'api';
import { useLogout } from './useLogout';

const BEAT_INTERVAL = 5 * 60000; // every 5 minutes

export const useBeat = () => {
  const token = useGetMain(getUserToken);
  const { logout } = useLogout();

  const beat = async () => {
    try {
      await beatApi(token);
    } catch (e) {
      console.error(e);
      logout();
    }
  };

  const interval = React.useRef<NodeJS.Timer>();

  React.useEffect(() => {
    clearInterval(interval.current);

    interval.current = setInterval(() => {
      if (token) {
        beat();
      }
    }, BEAT_INTERVAL);

    return () => clearInterval(interval.current);
  }, [token]);

  return null;
};
