import React from 'react';

export interface UseButtons {
  styleValue: string;
  onStyleSelected: (ev: any) => void;
  onApplyStyle: () => void;
}

export const useButtons = (styleOptions: any[], onStyleApplied: (getData: any, apply: any) => void): UseButtons => {
  const [styleValue, setStyleValue] = React.useState('');

  const onStyleSelected = (ev: any) => {
    const value = ev.target.value;
    setStyleValue(value);
  };

  const onApplyStyle = () => {
    const option = styleOptions.find((s) => s.value === styleValue);
    onStyleApplied(option.getData, option.apply);
  };

  return { styleValue, onStyleSelected, onApplyStyle };
};
