import React from 'react';
import { getPosition, getSoil, getText, useGetPlants } from 'stores/plants/usePlantsState';
import { getEditMode, getPage, getUserToken, useGetMain, useMainStateActions } from 'stores/main/useMainState';
import { resetNew as resetNewApi, resetRecommended as resetRecommendedApi } from 'api';
import { usePlantSearch } from '../usePlantSearch';
import { SearchType } from 'stores/plants';
import { EditMode, SnackbarMessageType } from 'stores/main';

export interface UseEditMenu {
  isVisible: boolean;
  isConfirmNewDialogOpen: boolean;
  isConfirmRecommendedDialogOpen: boolean;
  onShowConfirmNew: VoidFunction;
  onShowConfirmRecommended: VoidFunction;
  onCancelConfirmNew: VoidFunction;
  onCancelConfirmRecommended: VoidFunction;
  onResetNew: () => Promise<void>;
  onResetRecommended: () => Promise<void>;
}

export const useEditMenu = (): UseEditMenu => {
  const [isConfirmNewDialogOpen, setIsConfirmNewDialogOpen] = React.useState(false);
  const [isConfirmRecommendedDialogOpen, setIsConfirmRecommendedDialogOpen] = React.useState(false);

  const userToken = useGetMain(getUserToken);
  const page = useGetMain(getPage);
  const editMode = useGetMain(getEditMode);
  const { setSnackbar } = useMainStateActions();

  const text = useGetPlants(getText);
  const soil = useGetPlants(getSoil);
  const position = useGetPlants(getPosition);

  const { execSearch } = usePlantSearch();
  const isVisible = page === 'plants' && editMode === EditMode.Plants;

  const resetNew = async () => {
    try {
      await resetNewApi(userToken);

      await execSearch({
        searchType: SearchType.Search,
        pageNumber: 0,
        pageSize: 99999,
        soil,
        position,
        text,
        includeInactive: true,
      });
      setSnackbar('New status removed from all items.', SnackbarMessageType.Success);
      setIsConfirmNewDialogOpen(false);
    } catch (error) {
      console.error('ERROR: ', error);
      setSnackbar('Unable to remove New status. Please try again.', SnackbarMessageType.Error);
    }
  };

  const resetRecommended = async () => {
    try {
      await resetRecommendedApi(userToken);

      await execSearch({
        searchType: SearchType.Search,
        pageNumber: 0,
        pageSize: 99999,
        soil,
        position,
        text,
        includeInactive: true,
      });

      setSnackbar('Featured status removed from all items.', SnackbarMessageType.Success);
      setIsConfirmRecommendedDialogOpen(false);
    } catch (error) {
      console.error('ERROR: ', error);
      setSnackbar('Unable to remove Featured status. Please try again.', SnackbarMessageType.Error);
    }
  };

  return {
    isVisible,
    isConfirmNewDialogOpen,
    isConfirmRecommendedDialogOpen,
    onShowConfirmNew: () => setIsConfirmNewDialogOpen(true),
    onShowConfirmRecommended: () => setIsConfirmRecommendedDialogOpen(true),
    onCancelConfirmNew: () => setIsConfirmNewDialogOpen(false),
    onCancelConfirmRecommended: () => setIsConfirmRecommendedDialogOpen(false),
    onResetNew: resetNew,
    onResetRecommended: resetRecommended,
  };
};
