import React from 'react';
import { saveCatalogItem } from 'api';
import { Plant, Position, SearchType, Soil } from 'stores/plants';
import { getCount, getPlants, useGetPlants, usePlantsStateActions } from 'stores/plants/usePlantsState';
import { getUserToken, useGetMain, useMainStateActions } from 'stores/main/useMainState';
import { SnackbarMessageType } from 'stores/main';
import { ADMIN_PAGE_SIZE } from 'stores/constants';
import { usePlantSearch } from '../../usePlantSearch';

export interface UseCatalogItem {
  price: string;
  isActive: boolean;
  isRecommended: boolean;
  isNew: boolean;
  isDirty: boolean;
  onEditItem: VoidFunction;
  onChangePrice: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeIsActive: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeIsRecommended: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeIsNew: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearchFamily: VoidFunction;
  onCancel: VoidFunction;
  onSave: VoidFunction;
}

export const useCatalogItem = (item: Plant): UseCatalogItem => {
  const [price, setPrice] = React.useState(item.price.toFixed(2));
  const [isActive, setIsActive] = React.useState(item.isActive);
  const [isRecommended, setIsRecommended] = React.useState(item.isRecommended);
  const [isNew, setIsNew] = React.useState(item.isNew);
  const [isDirty, setIsDirty] = React.useState(false);

  const userToken = useGetMain(getUserToken);
  const { setSnackbar } = useMainStateActions();

  const plants = useGetPlants(getPlants);
  const count = useGetPlants(getCount);
  const { setEditorDialogPlant, setIsEditorDialogOpen, setSearchType, setText, setSoil, setPosition, setPlants, setFamily } = usePlantsStateActions();
  const { execSearch } = usePlantSearch();

  var rgx = /^[0-9]*\.?[0-9]*$/;

  const onEditItem = () => {
    setEditorDialogPlant(item);
    setIsEditorDialogOpen(true);
  };

  const onChangePrice = (e: any) => {
    const value = e.target.value;
    if (value.match(rgx)) {
      setPrice(value);
    }
    setIsDirty(true);
  };

  const onChangeIsActive = () => {
    setIsActive((current) => !current);
    setIsDirty(true);
  };

  const onChangeIsRecommended = () => {
    setIsRecommended((current) => !current);
    setIsDirty(true);
  };

  const onChangeIsNew = () => {
    setIsNew((current) => !current);
    setIsDirty(true);
  };

  const onSearchFamily = async () => {
    setSearchType(SearchType.Family);
    setFamily(item.family.familyName);
    setText('');
    setSoil(Soil.All);
    setPosition(Position.All); // <=== This can all be done in one call
    await execSearch({
      searchType: SearchType.Family,
      pageNumber: 0,
      pageSize: ADMIN_PAGE_SIZE,
      soil: Soil.All,
      position: Position.All,
      text: item.family.familyName,
      includeInactive: true,
    });
  };

  const onCancel = () => {
    setPrice(item.price.toFixed(2));
    setIsActive(item.isActive);
    setIsRecommended(item.isRecommended);
    setIsNew(item.isNew);
    setIsDirty(false);
  };

  const onSave = async () => {
    const updatedItem = {
      ...item,
      price: parseFloat(price),
      isActive,
      isRecommended,
      isNew,
    };

    const request = {
      ...updatedItem,
      familyId: item.family.familyId,
      soilId: item.soil.soilId,
      positionId: item.position.positionId,
    };

    try {
      await saveCatalogItem(request, userToken);
      setSnackbar('Item saved', SnackbarMessageType.Success);

      setPlants(
        plants.map((item) => (item.catalogId === request.catalogId ? updatedItem : item)),
        count
      );
      setIsDirty(false);
    } catch (error) {
      setSnackbar('Error saving item', SnackbarMessageType.Error);
    }
  };

  return {
    price,
    isActive,
    isRecommended,
    isNew,
    isDirty,
    onEditItem,
    onChangePrice,
    onChangeIsActive,
    onChangeIsRecommended,
    onChangeIsNew,
    onSearchFamily,
    onCancel,
    onSave,
  };
};
