import React from 'react';
import Button from '@mui/material/Button';
import MuiDialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type ButtonDefinition = {
  text: string;
  action: any;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined;
};

interface Props {
  isOpen: boolean;
  title: string;
  content: string;
  buttons: any;
  onCancel: any;
}

export const Dialog = (props: Props): React.ReactElement => {
  const { isOpen, title, content, buttons, onCancel } = props;

  return (
    <MuiDialog
      open={isOpen}
      keepMounted
      onClose={onCancel}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      data-testid="Dialog"
    >
      <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">{content}</DialogContentText>
      </DialogContent>

      <DialogActions>
        {buttons.map((button: ButtonDefinition, index: number) => (
          <Button key={`button_${index}`} onClick={button.action} color={button.color || 'primary'}>
            {button.text}
          </Button>
        ))}
      </DialogActions>
    </MuiDialog>
  );
};
