import { useEffect } from 'react';
import { usePlantSearch } from 'components/Plants/usePlantSearch';
import { getEditMode, getPage, getUserToken, useGetMain, useMainStateActions } from 'stores/main/useMainState';
import { ADMIN_PAGE_SIZE, PAGE_SIZE } from 'stores/constants';
import { getPosition, getSearchType, getSoil, getText, useGetPlants } from 'stores/plants/usePlantsState';
import { EditMode } from 'stores/main';

export interface UseEditMenu {
  page: string;
  editMode: EditMode;
  isSignedIn: boolean;
  setEditMode: (value: EditMode) => void;
}

export const useEditMenu = (): UseEditMenu => {
  const page = useGetMain(getPage);
  const editMode = useGetMain(getEditMode);
  const { setEditMode } = useMainStateActions();

  const isSignedIn = !!useGetMain(getUserToken);
  const soil = useGetPlants(getSoil);
  const position = useGetPlants(getPosition);
  const text = useGetPlants(getText);
  const searchType = useGetPlants(getSearchType);

  const { execSearch } = usePlantSearch();

  const onSetEditMode = (editMode: EditMode) => {
    setEditMode(editMode);
  };

  useEffect(() => {
    if (page === 'plants' && [EditMode.View, EditMode.Plants].includes(editMode)) {
      execSearch({
        pageNumber: 0,
        pageSize: editMode === EditMode.Plants ? ADMIN_PAGE_SIZE : PAGE_SIZE,
        searchType,
        soil,
        position,
        text,
        includeInactive: editMode === EditMode.Plants,
      });
    }
  }, [editMode]);

  return { page, editMode, isSignedIn, setEditMode: onSetEditMode };
};
