import { useState, useEffect } from 'react';
import { Section } from 'stores/main';

export interface UseInputFields {
  markdownText: string;
  styleText: string;
  isStyleError: boolean;
  onMarkdownTextChanged: (value: string) => void;
  onStyleTextChanged: (value: string) => void;
}

// TODO: change to debounce
const markdownSaveTimeout = 1000;
const styleSaveTimeout = 2000;
let markdownTimer: any = null;
let styleTimer: any = null;

export const useInputFields = (
  section: Section,
  page: string,
  onMarkdownChanged: (value: string) => void,
  onStyleChanged: (value: string) => void
): UseInputFields => {
  const [markdownText, setMarkdownText] = useState('');
  const [styleText, setStyleText] = useState('');
  const [isStyleError, setIsStyleError] = useState(false);

  useEffect(() => {
    setStyleText(section.style || '');
  }, [section.style]);

  useEffect(() => {
    setMarkdownText(section.markdown || '');
  }, [section.markdown]);

  useEffect(() => {
    setMarkdownText('');
    setStyleText('');
    setIsStyleError(false);
  }, [page]);

  const onMarkdownTextChanged = (value: string) => {
    clearTimeout(markdownTimer);
    setMarkdownText(value);
    markdownTimer = setTimeout(() => {
      onMarkdownChanged(value);
    }, markdownSaveTimeout);
  };

  const onStyleTextChanged = (value: string) => {
    clearTimeout(styleTimer);
    setStyleText(value);
    styleTimer = setTimeout(() => {
      try {
        const style = JSON.parse(value);
        if (typeof style === 'object') {
          const formattedStyle = JSON.stringify(style, null, '\t');
          onStyleChanged(formattedStyle);
          setIsStyleError(false);
        } else {
          setIsStyleError(true);
        }
      } catch {
        setIsStyleError(true);
      }
    }, styleSaveTimeout);
  };

  return { markdownText, styleText, isStyleError, onMarkdownTextChanged, onStyleTextChanged };
};
