import {
  getResources,
  getResourcesErrorContent,
  getResourcesIsDialogOpen,
  getResourcesIsSaving,
  getUserToken,
  useGetMain,
  useMainStateActions,
} from 'stores/main/useMainState';
import { Resource } from 'stores/main';
import { getResources as getResourcesApi } from 'api';

export interface UseResources {
  resources: Resource[];
  isSaving: boolean;
  isDialogOpen: boolean;
  errorContent: string;
  loadResources: () => Promise<void>;
  closeDialog: VoidFunction;
}

export const useResources = (): UseResources => {
  const resources = useGetMain(getResources);
  const isSaving = useGetMain(getResourcesIsSaving);
  const isDialogOpen = useGetMain(getResourcesIsDialogOpen);
  const errorContent = useGetMain(getResourcesErrorContent);
  const userToken = useGetMain(getUserToken);

  const { setResources, setResourcesIsDialogOpen } = useMainStateActions();

  const loadResources = async () => {
    const resources = await getResourcesApi(userToken);
    setResources(resources);
  };

  const closeDialog = () => setResourcesIsDialogOpen(false);

  return { resources, isSaving, isDialogOpen, errorContent, loadResources, closeDialog };
};
