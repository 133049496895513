import { createUseStyles } from 'react-jss';
import { Markdown } from '../../Markdown';

type Props = {
  style: Record<string, unknown>;
  source: string;
};

export const StyledMarkdown = ({ style, source }: Props) => {
  const useStyles = createUseStyles({ root: style });
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Markdown source={source} />
    </div>
  );
};
