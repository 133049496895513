import React from 'react';
import { TextField, IconButton, Icons } from 'components/common';
import { usePageItem } from './usePageItem';
import style from './style.module.scss';
import { Page } from 'stores/main';

interface Props {
  id: number;
  label: string;
  url: string;
  canDelete: boolean;
  onMove: (direction: Direction, id: number) => void;
  onDelete: (id: number) => void;
  onChange: (page: Page) => void;
}

export enum Direction {
  Up = -1.5,
  Down = 1.5,
}

export const PageItem = (props: Props): React.ReactElement => {
  const { id, canDelete, onMove, onDelete } = props;
  const { label, url, onLabelChanged, onUrlChanged } = usePageItem(props.id, props.label, props.url, props.onChange);

  return (
    <div className={style.pageItem}>
      <div className={style.cell}>
        <TextField value={url || ''} disabled={!canDelete} onChange={(e: { target: { value: string } }) => onUrlChanged(e.target.value)} />
      </div>

      <div className={style.cell}>
        <TextField value={label} disabled={!canDelete} onChange={(e: { target: { value: string } }) => onLabelChanged(e.target.value)} />
      </div>

      <div className={style.cell}>
        <div className={style.buttons}>
          <IconButton color="secondary" onClick={() => onMove(Direction.Up, id)} fab={false}>
            <Icons.ArrowUpward />
          </IconButton>
          <IconButton color="secondary" onClick={() => onMove(Direction.Down, id)} fab={false}>
            <Icons.ArrowDownward />
          </IconButton>
          <IconButton disabled={!canDelete} color="primary" onClick={() => onDelete(id)}>
            <Icons.DeleteForever />
          </IconButton>
        </div>
      </div>
    </div>
  );
};
