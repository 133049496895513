import { PayloadAction, createReducer, createAction } from '@reduxjs/toolkit';
import { RootState } from '../';
import { getSession } from 'utilities/localStorage';
import { getCookieConsentValue } from 'react-cookie-consent';

export interface MainState {
  cookieConsent: CookieConsent;
  content: { [page: string]: Section[] };
  pages: Page[];
  page: string;
  editMode: EditMode;
  resources: Resource[];
  resourcesIsSaving: boolean;
  resourcesUploads: any[];
  resourcesErrorContent: string;
  resourcesIsDialogOpen: boolean;
  sectionIndex: number | null;
  userToken: any;
  snackbar: any;
}

export const getState = (state: RootState): MainState => state.main;

export enum CookieConsent {
  Init = 'init',
  Accepted = 'accepted',
  Declined = 'declined',
}

export enum SnackbarMessageType {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
}

export enum EditMode {
  View = 'VIEW',
  Content = 'CONTENT',
  Plants = 'PLANTS',
  Pages = 'PAGES',
  Header = 'HEADER',
  Footer = 'FOOTER',
  Styles = 'STYLES',
}

export type Page = { id: number; url: string; label: string; tabOrder: number };
export type Section = { id: string; markdown: string; style: string; disabled?: boolean };
export type Content = { page: string; sections: Section[] };
export type UserToken = { username: string; token: string; role: string };
export type Snackbar = { message: string; messageType: string };
export type Resource = { name: string; type: string };
export type Upload = { name: string; content: any; mime: string };
export type SnackbarMessage = { message: string; messageType: SnackbarMessageType };

export const setCookieConsent = createAction<CookieConsent>('main/setCookieConsent');
export const setPages = createAction<Page[]>('main/setPages');
export const setPage = createAction<string>('main/setPage');
export const setSections = createAction<Section[]>('main/setSections');
export const setUserToken = createAction<UserToken>('main/setUserToken');
export const clearUserToken = createAction('main/clearUserToken');
export const setEditMode = createAction<EditMode>('main/setEditMode');
export const setResources = createAction<any[]>('main/setResources');
export const setResourcesIsSaving = createAction<boolean>('main/setResourcesIsSaving');
export const setResourcesUploads = createAction<Upload[]>('main/setResourcesUploads');
export const setResourcesErrorContent = createAction<string>('main/setResourcesErrorContent');
export const setResourcesIsDialogOpen = createAction<boolean>('main/setResourcesIsDialogOpen');
export const setSectionIndex = createAction<number>('main/setSectionIndex');
export const clearSectionIndex = createAction('main/clearSectionIndex');
export const setSnackbar = createAction<SnackbarMessage>('main/setSnackbar');

const mainState = createReducer<MainState>(
  {
    cookieConsent: getCookieConsentValue('cookieConsent') ? CookieConsent.Accepted : CookieConsent.Init,
    pages: [],
    content: {},
    page: '',
    editMode: EditMode.View,
    resources: [],
    resourcesIsSaving: false,
    resourcesUploads: [],
    resourcesErrorContent: '',
    resourcesIsDialogOpen: false,
    sectionIndex: null,
    userToken: getSession('userToken') || null,
    snackbar: null,
  },
  (builder) => {
    builder.addCase(setCookieConsent, (state, action: PayloadAction<CookieConsent>) => {
      state.cookieConsent = action.payload;
    });

    builder.addCase(setPages, (state, action: PayloadAction<Page[]>) => {
      state.pages = action.payload;
    });

    builder.addCase(setPage, (state, action: PayloadAction<string>) => {
      state.page = action.payload;
    });

    builder.addCase(setSections, (state, action: PayloadAction<Section[]>) => {
      state.content[state.page] = action.payload;
    });

    builder.addCase(setUserToken, (state, action: PayloadAction<UserToken>) => {
      state.userToken = action.payload;
    });

    builder.addCase(clearUserToken, (state) => {
      state.userToken = null;
    });

    builder.addCase(setEditMode, (state, action: PayloadAction<EditMode>) => {
      state.editMode = action.payload;
    });

    builder.addCase(setResources, (state, action: PayloadAction<any[]>) => {
      state.resources = action.payload;
    });

    builder.addCase(setResourcesIsSaving, (state, action: PayloadAction<boolean>) => {
      state.resourcesIsSaving = action.payload;
    });

    builder.addCase(setResourcesUploads, (state, action: PayloadAction<Upload[]>) => {
      state.resourcesUploads = action.payload;
    });

    builder.addCase(setResourcesErrorContent, (state, action: PayloadAction<string>) => {
      state.resourcesErrorContent = action.payload;
    });

    builder.addCase(setResourcesIsDialogOpen, (state, action: PayloadAction<boolean>) => {
      state.resourcesIsDialogOpen = action.payload;
    });

    builder.addCase(setSectionIndex, (state, action: PayloadAction<number>) => {
      state.sectionIndex = action.payload;
    });

    builder.addCase(clearSectionIndex, (state) => {
      state.sectionIndex = null;
    });

    builder.addCase(setSnackbar, (state, action: PayloadAction<SnackbarMessage>) => {
      state.snackbar = action.payload;
    });
  }
);

export default mainState;
