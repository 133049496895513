import React from 'react';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Plant } from 'stores/plants';
import { useCatalogItem } from './useCatalogItem';
import classnames from 'classnames';
import { getImageUrl } from 'utilities/imageUtils';
import style from './style.module.scss';

interface Props {
  item: Plant;
  onDeleteCatalogItem: (item: any) => void;
}

export const CatalogItem = (props: Props): JSX.Element => {
  const { item, onDeleteCatalogItem } = props;

  const {
    price,
    isActive,
    isRecommended,
    isNew,
    isDirty,
    onEditItem,
    onChangePrice,
    onChangeIsActive,
    onChangeIsRecommended,
    onChangeIsNew,
    onSearchFamily,
    onCancel,
    onSave,
  } = useCatalogItem(item);

  return (
    <div className={classnames(style.catalogItem, { [style.isActive]: item.isActive })}>
      <div className={style.title}>
        <div className={style.column}>
          <Fab color="secondary" onClick={onEditItem}>
            {item.hasImage ? (
              <div className={style.thumbnail}>
                <img className={style.thumbnail} src={getImageUrl('images_thumbnail', item)} alt="" />
              </div>
            ) : (
              <EditIcon />
            )}
          </Fab>
        </div>
        <h2 className={style.name}>
          {item.name}
          {item.genericName ? ` (${item.genericName})` : ''}
        </h2>
      </div>

      <div className={style.catalogItemContents}>
        <div className={style.column}>
          <div className={style.price}>
            <TextField label="Price" value={price} onChange={onChangePrice} />
          </div>
          <div>
            <FormControlLabel control={<Switch checked={isActive} onChange={onChangeIsActive} name="Active" color="primary" />} label="Active" />
          </div>
          <div>
            <FormControlLabel
              control={<Switch checked={isRecommended} onChange={onChangeIsRecommended} name="Recommended" color="primary" />}
              label="Featured"
            />
          </div>
          <div>
            <FormControlLabel control={<Switch checked={isNew} onChange={onChangeIsNew} name="New" color="primary" />} label="New" />
          </div>
        </div>

        <div className={style.column}>
          <div>
            <Button color="secondary" variant="outlined" className={style.familyButton} onClick={onSearchFamily}>
              {item.family.familyName}
            </Button>
          </div>
          <div className={style.stats}>
            <span>Temp</span>
            <span>{item.temp}</span>
          </div>
          <div className={style.stats}>
            <span>Height</span>
            <span>{item.height}</span>
          </div>
          <div className={style.stats}>
            <span>Position</span>
            <span>{item.position.positionName}</span>
          </div>
          <div className={style.stats}>
            <span>Soil</span>
            <span>{item.soil.soilName}</span>
          </div>
        </div>

        {isDirty ? (
          <div className={classnames(style.column, style.buttons)}>
            <div>
              <Fab className={style.cancelButton} onClick={onCancel}>
                <CloseIcon />
              </Fab>
              <Fab className={style.saveButton} onClick={onSave}>
                <CheckIcon />
              </Fab>
            </div>
          </div>
        ) : (
          <div className={classnames(style.column, style.buttons)}>
            <div>
              <Fab onClick={() => onDeleteCatalogItem(item)} color="secondary">
                <DeleteForeverIcon />
              </Fab>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
