import { createTheme, ThemeProvider } from '@mui/material/styles';

import colors from './colors';

const theme = createTheme({
  spacing: 16,
  typography: {
    fontFamily: '"Roboto", "sans-serif"',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightBold: 500,
    //fontWeightExtraBold: 700,
    fontSize: 16,
  },
  palette: {
    ...colors,
    background: {
      paper: colors.themeColor1,
    },
    error: {
      main: colors.themeColor6,
    },
    primary: {
      light: colors.themeColor1,
      main: colors.themeColor2,
      dark: colors.themeColor3,
      contrastText: colors.themeMonoF,
    },
    secondary: {
      light: colors.themeColor1,
      main: colors.themeColor4,
      dark: colors.themeColor3,
      contrastText: colors.themeMonoF,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

interface Props {
  children: React.ReactNode;
}

const MainTheme = (props: Props) => {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default MainTheme;
