import { useCallback } from 'react';
import { useEditMenu } from './useEditMenu';

import style from './style.module.scss';
import { IconButton, Icons } from 'components/common';
import { EditMode } from 'stores/main';
import { EditMenu as PlantsEditMenu } from 'components/Plants/EditMenu/EditMenu';

export const EditMenu = (): JSX.Element | null => {
  const { page, editMode, isSignedIn, setEditMode } = useEditMenu();

  const makeButton = useCallback(
    (mode: EditMode, icon: JSX.Element) => (
      <IconButton color={mode === editMode ? 'primary' : 'default'} onClick={() => setEditMode(mode)}>
        {icon}
      </IconButton>
    ),
    [editMode]
  );

  if (!isSignedIn) return null;

  return (
    <div className={style.editMenu}>
      <div className={style.buttons}>
        {makeButton(EditMode.View, <Icons.Visibility />)}
        {makeButton(EditMode.Content, <Icons.ModeEdit />)}
        {page === 'plants' && makeButton(EditMode.Plants, <Icons.LocalFlorist />)}
        {makeButton(EditMode.Pages, <Icons.AutoStories />)}
      </div>

      <div>{page === 'plants' && <PlantsEditMenu />}</div>
    </div>
  );
};
