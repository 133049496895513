import { LoadStatus } from 'stores/constants';

import { usePlantsStateActions } from 'stores/plants/usePlantsState';
import { getPlants } from 'api';
import { SnackbarMessageType } from 'stores/main';
import { Position, SearchType, Soil } from 'stores/plants';
import { useMainStateActions } from 'stores/main/useMainState';

type SearchParameters = {
  searchType: SearchType;
  pageNumber: number;
  pageSize: number;
  soil: Soil;
  position: Position;
  text: string;
  includeInactive: boolean;
  family?: string;
};

export interface UsePlantSearch {
  execSearch: (searchParameters: SearchParameters) => Promise<void>;
}

export const usePlantSearch = (): UsePlantSearch => {
  const { setSnackbar } = useMainStateActions();
  const { setLoadStatus, setPlants } = usePlantsStateActions();

  const execSearch = async (searchParameters: SearchParameters) => {
    setLoadStatus(LoadStatus.Loading);
    setPlants([], 0);

    try {
      const { items, count } = await getPlants(searchParameters);
      setPlants(items, count);
    } catch (e) {
      setPlants([], 0);
      setSnackbar('Unable to load items. Please try again.', SnackbarMessageType.Error);
    }

    setLoadStatus(LoadStatus.Loaded);
  };

  return { execSearch };
};
