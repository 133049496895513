export const setLocal = (key: string, value: unknown) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const setSession = (key: string, value: unknown) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const getLocal = <T>(key: string) => {
  const value = localStorage.getItem(key);
  return value ? (JSON.parse(value) as T) : null;
};

export const getSession = <T>(key: string) => {
  const value = sessionStorage.getItem(key);
  return value ? (JSON.parse(value) as T) : null;
};
