import React from 'react';
import { PageItem } from './PageItem';
import { usePageEditor } from './usePageEditor';
import { Button, Icons } from 'components/common';
import style from './style.module.scss';
import itemStyle from './PageItem/style.module.scss';
import { ConfirmDialog } from 'components/ConfirmDialog';

export const PagesEditor = (): React.ReactElement => {
  const { pages, isDialogOpen, canSave, onMove, onAdd, onDelete, onDeleteCancel, onDeleteConfirm, onChange, onSave } = usePageEditor();

  return (
    <>
      <div className={style.container}>
        <h1>Site Pages</h1>
        <div className={style.buttons}>
          <Button color="primary" variant="contained" onClick={onAdd} startIcon={<Icons.AddCircle />}>
            Add new page
          </Button>
          <Button color="primary" variant="contained" onClick={onSave} startIcon={<Icons.Save />} disabled={!canSave}>
            Save
          </Button>
        </div>

        <div className={itemStyle.pageItem}>
          <div className={itemStyle.cell}>
            <h3>URL</h3>
          </div>
          <div className={itemStyle.cell}>
            <h3>Label</h3>
          </div>
        </div>

        {pages.map(({ id, label, url, tabOrder }) => (
          <div className={style.page} key={`${id}${tabOrder}`}>
            <PageItem id={id} label={label} url={url} canDelete={url !== null} onMove={onMove} onDelete={onDelete} onChange={onChange} />
          </div>
        ))}
      </div>

      <ConfirmDialog
        title="Confirm delete"
        text="Are you sure you want to delete this page?"
        isOpen={isDialogOpen}
        onConfirm={onDeleteConfirm}
        onCancel={onDeleteCancel}
      />
    </>
  );
};
