import React from 'react';
import { IconButton, Icons } from 'components/common';
import { useSectionEditButtons } from './useSectionEditButtons';
import { ConfirmDialog } from 'components/ConfirmDialog';
import style from './style.module.scss';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import colors from 'styles/colors';

interface Props {
  canUp: boolean;
  canDown: boolean;
  disabled: boolean;
  onUp: VoidFunction;
  onDown: VoidFunction;
  onDuplicate: VoidFunction;
  onDelete: VoidFunction;
  onToggleDisabled: VoidFunction;
}

export const SectionEditButtons = (props: Props): React.ReactElement => {
  const { canUp, canDown, disabled, onUp, onDown, onDuplicate, onToggleDisabled } = props;
  const { showDialog, onDelete, onDeleteConfirm, onDeleteCancel } = useSectionEditButtons(props.onDelete);

  return (
    <>
      <div className={style.container}>
        <ThemeProvider
          theme={createTheme({
            palette: {
              primary: {
                main: colors.themeColor11,
              },
              secondary: {
                main: colors.themeColor12,
              },
            },
          })}
        >
          <IconButton color={disabled ? 'primary' : 'secondary'} onClick={onToggleDisabled} ariaLabel="Show/Hide">
            {disabled ? <Icons.VisibilityOff /> : <Icons.Visibility />}
          </IconButton>
        </ThemeProvider>
        <IconButton color="primary" onClick={onUp} disabled={!canUp} ariaLabel="Up">
          <Icons.ArrowUpward />
        </IconButton>
        <IconButton color="primary" onClick={onDown} disabled={!canDown} ariaLabel="Down">
          <Icons.ArrowDownward />
        </IconButton>
        <IconButton color="primary" onClick={onDuplicate} ariaLabel="Duplicate">
          <Icons.ContentCopy />
        </IconButton>
        <IconButton color="primary" onClick={onDelete} ariaLabel="Delete">
          <Icons.DeleteForever />
        </IconButton>
      </div>

      <ConfirmDialog
        title="Confirm delete"
        text="Are you sure you want to delete this section?"
        isOpen={showDialog}
        onConfirm={onDeleteConfirm}
        onCancel={onDeleteCancel}
      />
    </>
  );
};
