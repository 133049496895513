import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import siteConfig from '../../siteConfig.json';
import style from './style.module.scss';

export const Footer = (): React.ReactElement => {
  const currentYear = new Date().getFullYear();

  const {
    footer: { primaryLinks, secondaryLinks, copyright, address, fromYear, registration },
  } = siteConfig;

  return (
    <div className={classnames(style.footer, 'disable-select')}>
      <div className={style.contents}>
        <div className={style.column1}>
          <ul>
            {primaryLinks.map(({ label, url }) => (
              <li key={url}>
                <Link to={url}>{label}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div className={style.column2}>
          <ul>
            {secondaryLinks.map(({ label, url }) => (
              <li key={url}>
                <Link to={url}>{label}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div className={style.column3}>
          <ul>
            <li>
              {copyright} {fromYear}-{currentYear}
            </li>
            <li>{registration}</li>
            <li>{address}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
