import React from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import { useResourceList } from './useResourceList';

const styles = {
  resourceList: {
    display: 'flex',
    flexDirection: 'row',
    maxHeight: '40rem',
    overflowY: 'auto',
    flexWrap: 'wrap',
  },
  resourceItem: {
    width: '25rem',
    display: 'flex',
    alignItems: 'center',
    marginTop: '1rem',
    resourceItemName: {
      marginLeft: '1rem',
    },
    deleteButton: {
      marginLeft: '1rem',
    },
    copyToCliboardButton: {
      marginLeft: '0.5rem',
    },
    image: {
      background: '#999',
      height: '4rem',
      width: '4rem',
      padding: '0.25rem',
      border: '1px solid white',
    },
    imageType: {
      background: '#333',
      height: '4rem',
      width: '4rem',
      padding: '0.25rem',
      border: '1px solid white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#eee',
    },
  },
};

export const ResourceList = (): React.ReactElement => {
  const { resources, apiUrl, deleteResource, onCopyResourceToClipboard } = useResourceList();

  const DownloadImg = ({ name }: { name: string }) => {
    return <img src={`${apiUrl}/${name}`} style={styles.resourceItem.image} alt={'Resource'} />;
  };

  const iconTypes = ['JPG', 'PNG', 'GIF', 'SVG'];
  return (
    <div style={styles.resourceList as any}>
      {resources.map((r, idx) => (
        <div style={styles.resourceItem} key={`res_${idx}`}>
          <Link href={`${apiUrl}/${r.name}`} target="_blank">
            {iconTypes.includes(r.type.toUpperCase()) ? <DownloadImg name={r.name} /> : <div style={styles.resourceItem.imageType}>{r.type}</div>}
          </Link>
          <IconButton size="small" color="secondary" aria-label="add" onClick={() => deleteResource(r.name)} style={styles.resourceItem.deleteButton}>
            <DeleteOutlineOutlinedIcon />
          </IconButton>
          <IconButton
            size="small"
            color="primary"
            aria-label="add"
            onClick={() => onCopyResourceToClipboard(r.name)}
            style={styles.resourceItem.copyToCliboardButton}
          >
            <FileCopyOutlinedIcon />
          </IconButton>
          <div style={styles.resourceItem.resourceItemName}>{r.name}</div>
        </div>
      ))}
    </div>
  );
};
