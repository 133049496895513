import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import style from './style.module.scss';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  title: string;
  text: string | React.ReactElement;
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmDialog = (props: Props) => {
  const { title, text, isOpen, onConfirm, onCancel } = props;

  return (
    <div className={style.dialog}>
      <Dialog
        open={isOpen}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        keepMounted
      >
        <div className={style.dialogContent}>
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-confirm-delete">{text}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onConfirm} color="primary">
              Yes
            </Button>
            <Button onClick={onCancel} color="primary" autoFocus>
              No
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};
