import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Spinner } from 'components/common';
import { Dialog } from '../Dialog';
import { ResourceList } from './ResourceList';
import { Upload } from '../Upload';

import style from './style.module.scss';
import { useResources } from './useResources';

export const Resources = () => {
  const { resources, loadResources, isSaving, isDialogOpen, closeDialog, errorContent } = useResources();

  const buttons = [{ text: 'OK', action: closeDialog }];

  React.useEffect(() => {
    loadResources();
  }, []);

  return (
    <>
      <Accordion className={style.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon className={style.icon} />} aria-controls="content" id="header">
          <h1 className={style.h1}>Resources</h1>
        </AccordionSummary>

        <AccordionDetails>
          <div className={style.resourcesContainer}>
            <div className={style.uploadContainer}>{isSaving ? <Spinner /> : <Upload />}</div>

            {resources && <ResourceList />}
          </div>
        </AccordionDetails>
      </Accordion>

      <Dialog isOpen={isDialogOpen} title="Error" buttons={buttons} onCancel={buttons[0].action} content={errorContent} />
    </>
  );
};
