import React from 'react';
import MuiTab from '@mui/material/Tab';

interface Props {
  label: string;
  component: any;
  to: string;
  style: any;
  value: string;
  className?: string;
}

export const Tab = (props: Props): React.ReactElement => {
  const { label, component, to, style, value, className } = props;
  return <MuiTab label={label} component={component} to={to} style={style} value={value} className={className} />;
};
