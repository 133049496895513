import { useEffect } from 'react';
import { Page, useMainStateActions, useGetMain, getPages } from 'stores/main/useMainState';
import { getPages as getPagesApi } from 'api';

export interface UseNav {
  tabs: Page[];
}

export const useNav = (): UseNav => {
  const pages = useGetMain(getPages);
  const { setPages } = useMainStateActions();

  const loadPages = async () => {
    setPages(await getPagesApi());
  };

  const tabs = pages
    .filter(({ label }) => !!label)
    .map((page) => ({
      ...page,
      url: `/${page.url ?? ''}`,
    }));

  useEffect(() => {
    loadPages();
  }, []);

  return { tabs };
};
