import { useLocation, Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Paper, Tab, Tabs } from '../common';
import { Drawer } from './Drawer';

import style from './style.module.scss';
import logo from 'assets/logo_40.png';
import { useBeat } from '../hooks/useBeat';
import { useNav } from './useNav';

export const Nav = () => {
  /* Keep-alive when logged in */
  useBeat();

  const { pathname } = useLocation();
  const theme = useTheme();

  const { tabs } = useNav();
  const tabValue = tabs.find((t) => t.url === pathname)?.url ? pathname : false;
  const paper = {
    background: theme.palette.background.paper,
  };

  const tab = {
    color: theme.palette.primary.contrastText,
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    opacity: 1,
  };

  return (
    <>
      <div className={style.nav}>
        <Paper square style={paper}>
          <div className={style.drawer}>
            <Drawer />
          </div>

          <div className={style.tabs}>
            <Tabs value={tabValue} indicatorColor="primary" textColor="primary">
              {tabs.map((t, idx) => (
                <Tab key={`tab${t.tabOrder}${t.url}`} label={t.label} component={Link} to={t.url} style={tab} value={t.url} className={style.tab} />
              ))}
            </Tabs>
          </div>
        </Paper>
      </div>
    </>
  );
};
