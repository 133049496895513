import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

interface Props {
  source: string;
}

export const Markdown = (props: Props) => {
  const { source } = props;

  return (
    <ReactMarkdown
      children={source}
      remarkPlugins={[gfm]}
      components={{
        link: (props) =>
          props.href?.startsWith('/') ? (
            <Link to={props.href}>{props.children}</Link>
          ) : (
            <a href={props.href} target="_blank" rel="noreferrer">
              {props.children}
            </a>
          ),
      }}
    />
  );
};
