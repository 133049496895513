import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { getResourcesUploads, getUserToken, useGetMain, useMainStateActions } from 'stores/main/useMainState';
import { saveResources as saveResourcesApi, getResources as getResourcesApi } from 'api';
import { SnackbarMessageType, Upload } from 'stores/main';
import { Upload as UploadType } from 'stores/main/index';

export interface UseUpload {
  saveResources: () => Promise<void>;
  uploads: Upload[];
  getRootProps: any;
  getInputProps: any;
  isDragActive: boolean;
  uploadNameChanged: (indes: number, name: string) => void;
  onDelete: (index: number) => void;
  saveDisabled: boolean;
}

export const useUpload = (): UseUpload => {
  const uploads = useGetMain(getResourcesUploads);
  const userToken = useGetMain(getUserToken);

  const { setResourcesIsSaving, setResources, setResourcesUploads, setResourcesErrorContent, setResourcesIsDialogOpen, setSnackbar } = useMainStateActions();

  const saveResources = async (): Promise<void> => {
    setResourcesIsSaving(true);

    let result;
    try {
      result = await saveResourcesApi(uploads, userToken);
      const resources = await getResourcesApi(userToken);
      setResources(resources);
      setResourcesIsSaving(false);
      setResourcesUploads([]);
      setSnackbar('Resources saved', SnackbarMessageType.Success);
    } catch {
      setResourcesErrorContent(`Failed to upload: ${result.fail}`);
      setResourcesIsDialogOpen(true);
      setSnackbar('Resources saved with some errors', SnackbarMessageType.Warning);
    }
  };

  const addUpload = (upload: Upload) => {
    setResourcesUploads([...uploads, upload]);
  };

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      setResourcesUploads([]);
      acceptedFiles.forEach((file: any) => {
        const reader = new FileReader();

        reader.onabort = () => console.error('file reading was aborted');
        reader.onerror = () => console.error('file reading has failed');
        reader.onload = () => {
          const base64 = reader.result;
          addUpload(newUpload(base64));
        };
        reader.readAsDataURL(file);
      });
    },
    [setResourcesUploads, addUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const newUpload = (content: any): UploadType => ({
    content,
    name: '',
    mime: content.slice(5, content.indexOf(';')),
  });

  const saveDisabled = !!uploads.find((u) => !u.name);

  const uploadNameChanged = (index: number, name: string) => {
    setResourcesUploads(uploads.map((upload, idx) => (idx === index ? { ...upload, name } : upload)));
  };

  const onDelete = (index: number) => {
    setResourcesUploads(uploads.filter((_, idx) => idx !== index));
  };

  return { uploads, saveResources, getRootProps, getInputProps, isDragActive, uploadNameChanged, onDelete, saveDisabled };
};
