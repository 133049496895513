import { useRef, useEffect } from 'react';

export type UseDebounce = Fn;

type Fn = (params: any) => void;

export const useDebounce = (func: Fn, timeout: number): UseDebounce => {
  const timer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    clearTimeout(timer.current);
  }, []);

  return (params) => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => func(params), timeout);
  };
};
