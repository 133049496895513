import { createTheme, ThemeProvider } from '@mui/material/styles';

import colors from './colors';

const theme = createTheme({
  spacing: 16,
  typography: {
    fontFamily: '"Roboto", "sans-serif"',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightBold: 500,
    fontSize: 15,
  },
  palette: {
    ...colors,
    primary: {
      main: colors.themeColor4,
    },
    secondary: {
      main: colors.themeColor9,
    },
    contrastThreshold: 3,
  },
});

interface Props {
  children: React.ReactNode;
}

const SearchTheme = (props: Props) => {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default SearchTheme;
