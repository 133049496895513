import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  getEditorDialogPlant,
  getFamilies,
  getFamiliesLoadStatus,
  getIsEditorDialogOpen,
  getLoadStatus,
  useGetPlants,
  usePlantsStateActions,
} from 'stores/plants/usePlantsState';
import { LoadStatus } from 'stores/constants';
import { resizeImage } from 'utilities/imageUtils';
import { Family, Plant } from 'stores/plants';
import { getUserToken, useGetMain, useMainStateActions } from 'stores/main/useMainState';
import { getFamilies as getFamiliesApi, saveCatalogItem as saveCatalogItemApi } from 'api';
import { SnackbarMessageType } from 'stores/main';
import { asPrice, isPrice } from 'utilities/regexUtils';

type HtmlEvent = React.ChangeEvent<HTMLInputElement>;

export interface UseCatalogItemEditor {
  loadStatus: LoadStatus;
  item: Plant;
  isEditorDialogOpen: boolean;
  imageBase64: any;
  isDragActive: boolean;
  isDeleteImage: boolean;
  canSave: boolean;
  name: string;
  genericName: string;
  families: Family[];
  newFamilyName: string;
  familyId: number;
  positionId: number;
  soilId: number;
  height: string;
  temp: string;
  price: string;
  description: string;
  getRootProps: any;
  getInputProps: any;
  isConfirmDeleteDialogOpen: boolean;
  onChangeDeleteImage: (event: HtmlEvent) => void;
  onChangeName: (event: HtmlEvent) => void;
  onChangeGenericName: (event: HtmlEvent) => void;
  onChangeFamilyId: (familyId: number) => void;
  onChangeNewFamilyName: (event: HtmlEvent) => void;
  onChangePositionId: (positionId: number) => void;
  onChangeSoilId: (soilId: number) => void;
  onChangeHeight: (event: HtmlEvent) => void;
  onChangeTemp: (event: HtmlEvent) => void;
  onChangePrice: (event: HtmlEvent) => void;
  onChangeDescription: (event: HtmlEvent) => void;
  onShowConfirmDeleteDialog: VoidFunction;
  onConfirmDelete: (isConfirmed: boolean) => Promise<void>;
  onSave: VoidFunction;
  onClose: VoidFunction;
}

export const useCatalogItemEditor = (onDeleteItem: (item: Plant) => void, onSearch: (overrides?: any) => void): UseCatalogItemEditor => {
  const [name, setName] = useState<string>('');
  const [genericName, setGenericName] = useState<string>('');
  const [familyId, setFamilyId] = useState<number>(0);
  const [newFamilyName, setNewFamilyName] = useState<string>('');
  const [positionId, setPositionId] = useState<number>(1);
  const [soilId, setSoilId] = useState<number>(1);
  const [height, setHeight] = useState<string>('');
  const [temp, setTemp] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [price, setPrice] = useState<string>('');
  const [imageBase64, setImageBase64] = useState<any>();
  const [image500Base64, setImage500Base64] = useState<any>();
  const [image100Base64, setImage100Base64] = useState<any>();
  const [isDeleteImage, setIsDeleteImage] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState<boolean>(false);

  const userToken = useGetMain(getUserToken);
  const { setSnackbar } = useMainStateActions();

  const isEditorDialogOpen = useGetPlants(getIsEditorDialogOpen);
  const item = useGetPlants(getEditorDialogPlant);
  const loadStatus = useGetPlants(getLoadStatus);
  const familiesLoadStatus = useGetPlants(getFamiliesLoadStatus);
  const families = useGetPlants(getFamilies);
  const { setIsEditorDialogOpen, setFamiliesLoadStatus, setFamilies } = usePlantsStateActions();

  useEffect(() => {
    if (!isEditorDialogOpen || !item) return;
    setName(item.name || '');
    setGenericName(item.genericName || '');
    setFamilyId(item.family.familyId);
    setNewFamilyName('');
    setPositionId(item.position.positionId);
    setSoilId(item.soil.soilId);
    setHeight(item.height || '');
    setTemp(item.temp || '');
    setDescription(item.description || '');
    setPrice(`${item.price}`);
    setIsDirty(false);
    setImageBase64(null);
    setImage500Base64(null);
    setImage100Base64(null);
    setIsDeleteImage(false);
  }, [item, isEditorDialogOpen]);

  const loadFamilies = async () => {
    setFamiliesLoadStatus(LoadStatus.Loading);

    try {
      const { items } = await getFamiliesApi(userToken);
      setFamilies(items);
    } catch (error) {
      console.error('ERROR: ', error);
      setSnackbar('Unable to load plant families', SnackbarMessageType.Error);
      setFamiliesLoadStatus(LoadStatus.Error);
    }
    setFamiliesLoadStatus(LoadStatus.Loaded);
  };

  useEffect(() => {
    if (familiesLoadStatus === LoadStatus.Required) {
      loadFamilies();
    }
  }, [familiesLoadStatus]);

  const canSave = !!name && ((familyId || 0) > 0 || !!newFamilyName) && !!height && !!temp && !!description && parseFloat(price) >= 0 && isDirty;

  const onDrop = React.useCallback(
    (acceptedFiles: any) => {
      if (acceptedFiles.length !== 1) {
        return setSnackbar('Only one image may be uploaded', SnackbarMessageType.Error);
      }
      if (acceptedFiles[0].type !== 'image/jpeg') {
        return setSnackbar('Only JPG images may be uploaded ', SnackbarMessageType.Error);
      }
      setIsDeleteImage(false);
      acceptedFiles.forEach((file: any) => {
        const reader = new FileReader();
        reader.onabort = () => setSnackbar('File reading was aborted', SnackbarMessageType.Warning);
        reader.onerror = () => setSnackbar('File reading has failed', SnackbarMessageType.Error);
        reader.onload = () =>
          resizeImage(file, null, ({ base64 }) => {
            setImageBase64(base64);
            resizeImage(file, { maxWidth: 500, maxHeight: 500 }, ({ base64 }) => {
              setImage500Base64(base64);
              resizeImage(file, { maxWidth: 100, maxHeight: 100 }, ({ base64 }) => {
                setImage100Base64(base64);
                setIsDirty(true);
              });
            });
          });
        reader.readAsDataURL(file);
      });
    },
    [setSnackbar]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxFiles: 1 });

  const onChangeDeleteImage = (e: HtmlEvent) => {
    setIsDeleteImage(e.target.checked);
    setIsDirty(true);
  };

  const onChangeName = (e: HtmlEvent) => {
    setName(e.target.value);
    setIsDirty(true);
  };

  const onChangeGenericName = (e: HtmlEvent) => {
    setGenericName(e.target.value);
    setIsDirty(true);
  };

  const onChangeFamilyId = (familyId: number) => {
    setFamilyId(familyId);
    setIsDirty(true);
  };

  const onChangeNewFamilyName = (e: HtmlEvent) => {
    setNewFamilyName(e.target.value);
    setIsDirty(true);
  };

  const onChangePositionId = (positionId: number) => {
    setPositionId(positionId);
    setIsDirty(true);
  };

  const onChangeSoilId = (soilId: number) => {
    setSoilId(soilId);
    setIsDirty(true);
  };

  const onChangeHeight = (e: HtmlEvent) => {
    setHeight(e.target.value);
    setIsDirty(true);
  };

  const onChangeTemp = (e: HtmlEvent) => {
    setTemp(e.target.value);
    setIsDirty(true);
  };

  const onChangeDescription = (e: HtmlEvent) => {
    setDescription(e.target.value);
    setIsDirty(true);
  };

  const onChangePrice = (e: HtmlEvent) => {
    if (isPrice(e.target.value)) {
      setPrice(e.target.value);
      setIsDirty(true);
    }
  };

  const onClose = () => {
    setIsEditorDialogOpen(false);
  };

  const onSave = async () => {
    const request = {
      ...item,
      name,
      genericName,
      familyId,
      newFamilyName,
      positionId,
      soilId,
      height,
      temp,
      description,
      price: price ? asPrice(price) : '',
      imageBase64,
      image500Base64,
      image100Base64,
      deleteImage: isDeleteImage,
    };

    try {
      await saveCatalogItemApi(request, userToken);
      setSnackbar('Plant saved', SnackbarMessageType.Success);
    } catch (error) {
      console.error('ERROR: ', error);
      setSnackbar('Unable to save plant', SnackbarMessageType.Error);
      return;
    }

    if (request.familyId === 0) {
      await loadFamilies();
    }

    setIsEditorDialogOpen(false);
    onSearch({ text: name });
  };

  const onShowConfirmDeleteDialog = () => {
    setIsConfirmDeleteDialogOpen(true);
  };

  const onConfirmDelete = async (isConfirmed: boolean) => {
    setIsConfirmDeleteDialogOpen(false);
    if (isConfirmed && item) {
      onDeleteItem(item);
      setIsEditorDialogOpen(false);
      setFamilies([]);
      setFamiliesLoadStatus(LoadStatus.Required);
    }
  };

  return {
    loadStatus,
    item: item as Plant,
    isEditorDialogOpen,
    imageBase64,
    isDragActive,
    isDeleteImage,
    canSave,
    name,
    genericName,
    families,
    familyId: familyId as number,
    newFamilyName,
    positionId: positionId as number,
    soilId: soilId as number,
    height,
    temp,
    price,
    description,
    isConfirmDeleteDialogOpen,
    getRootProps,
    getInputProps,
    onChangeDeleteImage,
    onChangeName,
    onChangeGenericName,
    onChangeFamilyId,
    onChangeNewFamilyName,
    onChangePositionId,
    onChangeSoilId,
    onChangeHeight,
    onChangeTemp,
    onChangePrice,
    onChangeDescription,
    onShowConfirmDeleteDialog,
    onConfirmDelete,
    onSave,
    onClose,
  };
};
