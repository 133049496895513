import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import CookieBanner from 'react-cookie-consent';
import { SnackbarProvider } from 'notistack';
import { useScrollToTop } from '../hooks/useScrollToTop';
import { SnackbarManager } from '../SnackbarManager/SnackbarManager';
import { Nav } from '../Nav';
import { Footer } from '../Footer';
import { SignIn } from '../SignIn';
import { EntireCatalog } from '../Plants/EntireCatalog';
import { getCookieConsent, getPage, useGetMain, useMainStateActions } from 'stores/main/useMainState';
import { CookieConsent } from 'stores/main';

import MainTheme from 'styles/MainTheme';
import style from './style.module.scss';
import { PagePicker } from './PagePicker';

const cookieBannerStyle = { background: '#455b44' };
const acceptButtonStyle = {
  background: 'white',
  color: 'black',
  fontSize: '1rem',
  border: 0,
  borderRadius: '5px',
  margin: '0.5rem',
  boxShadow: '0px 0px 3px 0 #d0ffce',
  fontFamily: 'inherit',
};

export const App = (): React.ReactElement => {
  const page = useGetMain(getPage);

  const cookieConsent = useGetMain(getCookieConsent);
  const { setCookieConsent } = useMainStateActions();

  const scrollRef = useScrollToTop(page);

  const router = createBrowserRouter([
    {
      path: '/', // home
      element: (
        <>
          <div className={style.contentWrapper}>
            <Nav />
            <PagePicker />
          </div>
          <Footer />
        </>
      ),
    },
    {
      path: '/signIn',
      element: (
        <>
          <div className={style.contentWrapper}>
            <Nav />
            <SignIn />
          </div>
          <Footer />
        </>
      ),
    },
    {
      path: ':page',
      element: (
        <>
          <div className={style.contentWrapper}>
            <Nav />
            <PagePicker />
          </div>
          <Footer />
        </>
      ),
    },
    {
      path: '/plants',
      element: (
        <>
          <div className={style.contentWrapper}>
            <Nav />
            <PagePicker page="plants" />
          </div>
          <Footer />
        </>
      ),
    },
    {
      path: '/plants/:plant',
      element: (
        <>
          <div className={style.contentWrapper}>
            <Nav />
            <PagePicker page="plants" showEditMenu={false} />
          </div>
          <Footer />
        </>
      ),
    },
    {
      path: '/plants/family/:family',
      element: (
        <>
          <div className={style.contentWrapper}>
            <Nav />
            <PagePicker page="plants" showEditMenu={false} />
          </div>
          <Footer />
        </>
      ),
    },
    {
      path: '/catalogue',
      element: (
        <>
          <div className={style.contentWrapper}>
            <Nav />
            <EntireCatalog />
          </div>
          <Footer />
        </>
      ),
    },
  ]);

  return (
    <MainTheme>
      <SnackbarProvider maxSnack={3}>
        <div className={style.container} ref={scrollRef}>
          <RouterProvider router={router} />
        </div>
        <SnackbarManager />

        {cookieConsent === CookieConsent.Init && (
          <CookieBanner
            location="bottom"
            cookieName="cookieConsent"
            buttonText="I accept"
            onAccept={() => {
              setCookieConsent(CookieConsent.Accepted);
            }}
            enableDeclineButton={true}
            declineButtonText="Reject all"
            onDecline={() => {
              setCookieConsent(CookieConsent.Declined);
            }}
            setDeclineCookie={false}
            declineButtonStyle={acceptButtonStyle}
            style={cookieBannerStyle}
            buttonStyle={acceptButtonStyle}
            expires={150}
          >
            This website uses cookies to enhance the user experience.
          </CookieBanner>
        )}
      </SnackbarProvider>
    </MainTheme>
  );
};
